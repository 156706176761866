import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.calendarHolder)?_c('div',[_c(_setup.Card,{attrs:{"title":_setup.calendarHolder.name,"icon":"mdi-calendar-multiple"}},[(_setup.calendars.length === 0)?_c('div',[_vm._v(" No calendars (yet) ")]):_c('div',[_c(_setup.EditableTable,{attrs:{"empty-table-text":"No calendars have been created (yet).","hideFilter":true,"validateRows":true,"inputObjects":_setup.calendars,"fields":[
          {fieldName:'name', displayName:'Name', placeholder:'', editable: true, type: String, rules:[_setup.rules.required]},
          {fieldName:'description', displayName:'Description', placeholder:'', editable: true, type: String},
          {fieldName:'url', displayName:'ICS URL', placeholder:'', editable: true, readOnly: true, hidden: true, type: String, rules:[_setup.rules.required]},
          {fieldName:'color', displayName:'Color', placeholder:'', editable: true, type: 'Color', rules:[_setup.rules.required]},
          {fieldName:'lastSync', displayName:'Sync', placeholder:'', type: 'Timestamp', },
          {fieldName:'calendarActions', displayName: '', editable: false},
        ],"save-method":(data) => _setup.api.calendar.saveCalendar(data)}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","fab":"","elevation":0},on:{"click":function($event){_setup.createCalendarDialog = true}}},[_c(VIcon,[_vm._v("mdi-calendar-plus")])],1)],1)],1),_c(_setup.Card,{attrs:{"title":"Calendar","icon":"mdi-calendar"}},[_c(VCalendar,{attrs:{"events":_setup.calendarEvents}})],1),_c(VDialog,{attrs:{"max-width":"1023"},model:{value:(_setup.createCalendarDialog),callback:function ($$v) {_setup.createCalendarDialog=$$v},expression:"createCalendarDialog"}},[_c(_setup.Card,{attrs:{"title":"Add Calendar","icon":"mdi-calendar","inDialog":""}},[_c(_setup.DynamicForm,{key:_setup.createCalendarDialog,attrs:{"object":{name: '', description: '', url: '', color: '#488207', },"fields":[
          {fieldName:'name', displayName:'Name', placeholder:'', type: String, rules:[_setup.rules.required]},
          {fieldName:'description', displayName:'Description', placeholder:'', type: String},
          {fieldName:'url', displayName:'ICS URL', placeholder:'', type: String, rules:[_setup.rules.required]},
          {fieldName:'color', displayName:'Color', placeholder:'', type: 'Color', rules:[_setup.rules.required]},
          ],"save-method":(data) => _setup.api.calendar.createCalendar(_setup.calendarHolder.id, data.name, data.description, data.color, data.url),"cancel-callback":() => _setup.createCalendarDialog = false,"success-callback":() => _setup.createCalendarDialog = false,"focus-index":0}})],1)],1)],1):_c('div',[_vm._v(" Loading... ")])
}
var staticRenderFns = []

export { render, staticRenderFns }