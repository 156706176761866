/*
Give this component an object and a list of fields, it will make a copy, edit the copy in a form, and call the save callback.
*/
<template>
  <div class="mt-4" v-if="isReady">
    <div v-for="(field, index) in fields" v-bind:key="index">
      <div v-if="!field.displayCondition || field.displayCondition(mutableObject)">
        <v-text-field v-if="field.type === String || (!field.type && mutableObject[field.fieldName] && mutableObject[field.fieldName].constructor === String)"
                      :autofocus="index == focusIndex"
                      outlined :label="field.displayName" v-model="mutableObject[field.fieldName]"
                      @keyup.native.enter="saveObject"
                      @keyup.native.esc="cancelEdit"
                      :rules="field.rules"
                      validate-on-blur
                      :disabled="isSaving || field.readOnly"
                      :readonly="field.readOnly"
                      :placeholder="field.placeholderFieldName ? mutableObject[field.placeholderFieldName] : field.placeholder"
                      :type="field.password ? 'password' : undefined"
                      :hint="field.hintFieldName ? mutableObject[field.hintFieldName] : field.hint"
                      persistent-hint
                      dense
                      :maxlength="field.maxlength"
        ></v-text-field>
        <v-combobox v-else-if="field.type === 'StringWithHints'"
                    :autofocus="index == focusIndex"
                    outlined :label="field.displayName" v-model="mutableObject[field.fieldName]"
                    @keyup.native.enter="saveObject"
                    @keyup.native.esc="cancelEdit"
                    :rules="field.rules"
                    validate-on-blur
                    :disabled="isSaving || field.readOnly"
                    :readonly="field.readOnly"
                    :items="field.values"
                    :placeholder="field.placeholderFieldName ? mutableObject[field.placeholderFieldName] : field.placeholder"
                    :type="field.password ? 'password' : undefined"
                    :hint="field.hintFieldName ? mutableObject[field.hintFieldName] : field.hint"
                    persistent-hint
                    dense
        ></v-combobox>
        <v-text-field v-else-if="field.type === Number || (!field.type && mutableObject[field.fieldName] && mutableObject[field.fieldName].constructor === Number)"
                      :autofocus="index == focusIndex"
                      outlined :label="field.displayName" v-model="mutableObject[field.fieldName]"
                      @keyup.native.enter="saveObject"
                      @keyup.native.esc="cancelEdit"
                      :rules="field.rules"
                      validate-on-blur
                      :disabled="isSaving || field.readOnly"
                      :readonly="field.readOnly"
                      :placeholder="field.placeholderFieldName ? mutableObject[field.placeholderFieldName] : field.placeholder"
                      type="number"
                      :hint="field.hintFieldName ? mutableObject[field.hintFieldName] : field.hint"
                      persistent-hint
                      dense
        ></v-text-field>
        <v-currency-field v-else-if="field.type === 'Currency' || (!field.type && mutableObject[field.fieldName] && mutableObject[field.fieldName].constructor === 'Currency')"
                          :autofocus="index == focusIndex"
                          outlined dense
                          :label="field.displayName" v-model="mutableObject[field.fieldName]"
                          @keyup.native.enter="saveObject"
                          @keyup.native.esc="cancelEdit"
                          :rules="field.rules"
                          validate-on-blur
                          :disabled="isSaving || field.readOnly"
                          :readonly="field.readOnly"
                          :placeholder="field.placeholderFieldName ? mutableObject[field.placeholderFieldName] : field.placeholder"
                          prefix="$"
                          :hint="field.hintFieldName ? mutableObject[field.hintFieldName] : field.hint"
        ></v-currency-field>
        <v-otp-input v-else-if="field.type === 'OTP' || (!field.type && mutableObject[field.fieldName] && mutableObject[field.fieldName].constructor === 'OTP')"
                     type="number"
                     outlined dense
                     :label="field.displayName"
                     v-model="mutableObject[field.fieldName]"
                     @keyup.native.enter="saveObject"
                     @keyup.native.esc="cancelEdit"
                     :rules="field.rules"
                     validate-on-blur
                     :disabled="isSaving || field.readOnly"
                     :readonly="field.readOnly"
                     :placeholder="field.placeholderFieldName ? mutableObject[field.placeholderFieldName] : field.placeholder"
                     :hint="field.hintFieldName ? mutableObject[field.hintFieldName] : field.hint"
        ></v-otp-input>
        <v-switch v-else-if="field.type === Boolean || (!field.type && mutableObject[field.fieldName] && mutableObject[field.fieldName].constructor === Boolean)"
                  style="margin-top: 0px;"
                  :label="field.displayName" v-model="mutableObject[field.fieldName]"
                  inset
                  :disabled="isSaving || field.readOnly"
                  :readonly="field.readOnly"
                  :hint="field.hintFieldName ? mutableObject[field.hintFieldName] : field.hint"
                  persistent-hint
        ></v-switch>
        <v-select v-else-if="field.type === 'Enumerator' || (!field.type && object[field.fieldName] && object[field.fieldName].constructor === Enumerator)"
                  :label="field.displayName" v-model="mutableObject[field.fieldName]"
                  inset outlined dense
                  :disabled="isSaving || field.readOnly"
                  :readonly="field.readOnly"
                  :items="field.values"
                  v-on:change="$emit(field.fieldName + 'change', mutableObject)"
                  :hint="field.hintFieldName ? mutableObject[field.hintFieldName] : field.hint"
                  persistent-hint
                  :clearable="field.clearable"
        >
          <template v-if="field.addAction" v-slot:append-outer>
            <v-icon @click="field.addAction(mutableObject)">mdi-plus</v-icon>
          </template>
        </v-select>
        <!--      Todo: Add support for editing (readOnly: false) here-->
        <span v-else-if="field.type === 'Timestamp' || (!field.type && mutableObject[field.fieldName] && mutableObject[field.fieldName].constructor === 'Timestamp')"

        >
        {{field.displayName}}: {{moment(mutableObject[field.fieldName]).from(moment.utc(now))}}
        </span>
        <div v-else-if="field.type === 'LocalDate' || (!field.type && mutableObject[field.fieldName] && mutableObject[field.fieldName].constructor === 'LocalDate')">
          <v-menu v-if="field.small"
                  v-model="field.showDatePickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                style="height: 40px;"
                :autofocus="index == focusIndex"
                outlined :label="field.displayName" v-model="mutableObject[field.fieldName]"
                :rules="field.rules"
                :disabled="isSaving || field.readOnly"
                readonly
                :placeholder="field.placeholderFieldName ? mutableObject[field.placeholderFieldName] : field.placeholder"
                :hint="field.hintFieldName ? mutableObject[field.hintFieldName] : field.hint"
                persistent-hint
                dense

                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              landscape color="primary"
              v-model="mutableObject[field.fieldName]"
              @input="field.showDatePickerMenu = false"
            ></v-date-picker>
          </v-menu>
          <fieldset v-else style="padding-left: 0px; padding-right: 0px; padding-bottom: 0px;">
            <legend style="margin-left: 8px; padding: 0px 4px; font-size: 0.75rem; color: rgba(255, 255, 255, 0.7);">
              {{ field.displayName }}
            </legend>
            <v-date-picker
              full-width landscape color="primary"
              v-model="mutableObject[field.fieldName]"
              @keyup.native.enter="saveObject"
              @keyup.native.esc="cancelEdit"
              :rules="field.rules"
              validate-on-blur
              :disabled="isSaving || field.readOnly"
              :readonly="field.readOnly"
            />
          </fieldset>
          <br/>
        </div>
        <div v-else-if="field.type === 'NamedCurrencyList'">
          <fieldset :class="isSaving || field.readOnly ? 'fieldset-disabled' : ''">
            <legend style="margin-left: 0px; padding: 0px 4px; font-size: 0.75rem; color: rgba(255, 255, 255, 0.7);">
              {{ field.displayName }}
            </legend>
            <draggable v-model="mutableObject[field.fieldName]" :disabled="isSaving || field.readOnly">
              <div v-for="(item, index) in mutableObject[field.fieldName]" :key="index" style="display: flex; margin: 8px; cursor: move;">
                <v-icon>mdi-drag-horizontal</v-icon>
                <v-text-field dense full-width
                              style="display:inline-flex; width: 100%"
                              outlined label="Name" v-model="item.name"
                              @keyup.native.enter="saveObject"
                              @keyup.native.esc="cancelEdit"
                              validate-on-blur
                              :disabled="isSaving"
                              :readonly="field.readOnly"
                              hide-details
                ></v-text-field>
                <v-currency-field dense
                                  style="display:inline-block; float: right;"
                                  outlined label="Amount" v-model="item.amount"
                                  @keyup.native.enter="saveObject"
                                  @keyup.native.esc="cancelEdit"
                                  :rules="field.rules"
                                  validate-on-blur
                                  :disabled="isSaving"
                                  :readonly="field.readOnly"
                                  prefix="$"
                                  hide-details
                ></v-currency-field>
                <v-btn small icon :elevation="0"
                       @click="mutableObject[field.fieldName].splice(index, 1);"
                       :disabled="isSaving"
                       style="float: right; margin: auto;">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </draggable>
            <v-btn small fab :elevation="0"
                   @click="mutableObject[field.fieldName].push({name: '', amount: null});"
                   :disabled="isSaving"
                   style="float: right;">
              <v-icon>mdi-book-plus</v-icon>
            </v-btn>
          </fieldset>
        </div>
        <div v-else-if="field.type === 'HOASubModules'">
          <div style="margin-bottom: 16px;">{{field.displayName}}</div>
          <span v-for="submodule in $store.state.modules.subModules" :key="submodule.id">
            <v-select
              v-if="submodule.id"
              inset outlined dense
              :label="submodule.name"
              :disabled="isSaving || field.readOnly"
              :readonly="field.readOnly"
              :items="['Read', 'Write']"
              v-on:change="(value) => $set(mutableObject[field.fieldName], submodule.className + ':' + submodule.id, value)"
              v-on:click:clear="() => $set(mutableObject[field.fieldName], submodule.className + ':' + submodule.id, null)"
              clearable
            />
            <div v-else style="margin-bottom: 16px;">{{submodule.name}}</div>
            <ul>
              <li v-for="subsubmodule in submodule.subModules" :key="subsubmodule.id">
                            <v-select
                              inset outlined dense
                              :label="subsubmodule.name"
                              :disabled="isSaving || field.readOnly"
                              :readonly="field.readOnly"
                              :items="['Read', 'Write']"
                              v-on:change="(value) => $set(mutableObject[field.fieldName], subsubmodule.className + ':' + subsubmodule.id, value)"
                              v-on:click:clear="() => $set(mutableObject[field.fieldName], subsubmodule.className + ':' + subsubmodule.id, null)"
                              clearable
                            />
                <ul>
                </ul>
              </li>
            </ul>
          </span>
        </div>
        <div v-else-if="field.type === 'AssetAllocationList'">
          <fieldset :class="isSaving || field.readOnly ? 'fieldset-disabled' : ''">
            <legend style="margin-left: 0px; padding: 0px 4px; font-size: 0.75rem; color: rgba(255, 255, 255, 0.7);">
              {{ field.displayName }}
            </legend>
            <draggable v-model="mutableObject[field.fieldName]" :disabled="isSaving || field.readOnly">
              <div v-for="(item, index) in mutableObject[field.fieldName]" :key="index" style="display: flex; margin: 8px; cursor: move;">
                <v-icon>mdi-drag-horizontal</v-icon>
                <v-select
                  dense full-width outlined
                  style="display:inline-flex; width: 100%"
                  :items="field.values"
                  v-model="item.assetClassId"
                  :disabled="isSaving || field.readOnly"
                  :readonly="field.readOnly"
                  hide-details
                >
                  <template v-if="field.addAction" v-slot:append>
                    <!--            <v-btn icon >-->
                    <v-icon @click="field.addAction(mutableObject)">mdi-plus</v-icon>
                    <!--            </v-btn>-->
                  </template>
                </v-select>
                <v-currency-field
                  dense
                  style="display:inline-block; float: right;"
                  outlined
                  label=""
                  v-model="item.weight"
                  @keyup.native.enter="saveObject"
                  @keyup.native.esc="cancelEdit"
                  :rules="field.rules"
                  validate-on-blur
                  :disabled="isSaving || field.readOnly"
                  :readonly="field.readOnly"
                  suffix="%"
                  :max=100
                  :min=0
                  hide-details
                >
                </v-currency-field>
                <v-btn small icon :elevation="0"
                       @click="mutableObject[field.fieldName].splice(index, 1);"
                       :disabled="isSaving || field.readOnly"
                       style="float: right; margin: auto;">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </draggable>
            <v-btn small fab :elevation="0"
                   @click="mutableObject[field.fieldName].push({weight: 100 - mutableObject[field.fieldName].reduce((a, b) => a + (b.weight || 0), 0)});"
                   :disabled="isSaving || field.readOnly"
                   style="float: right;">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </fieldset>
        </div>
        <div v-else-if="field.type === 'Color'">
          <v-color-picker
            style="margin: auto;"
            dot-size="25"
            hide-sliders
            show-swatches
            swatches-max-height="200"
            outlined
            :swatches="[['#0078d6', '#488207'], ['#da3c00', '#6a797f'], ['#fbe100', '#0b8286'], ['#e3008d', '#d03437']]"
            hide-inputs
            :label="field.displayName"
            v-model="mutableObject[field.fieldName]"
            :rules="field.rules"
            validate-on-blur
            :disabled="isSaving || field.readOnly"
            :readonly="field.readOnly"
            :items="field.values"
            dense
          ></v-color-picker>
        </div>
        <div v-else>
          Unknown type?
        </div>
      </div>
    </div>
    <v-card-actions>
      <ButtonWithConfirmation
        v-if="deleteMethod"
        :confirmation-text='"Are you sure you want to delete " + (mutableObject.name ? "\"" + mutableObject.name + "\"" : "this object") + "?"'
        confirmation-icon="mdi-delete"
        button-text="Delete"
        button-color="error"
        :disabled="isSaving"
        :loading="isDeleting"
        :action="() => deleteObject()"
        :success-callback="(response) => {
                    this.isSaving = false;
          this.isDeleting = false;
          if (this.successCallback) {
            this.successCallback(response);
            }
        }"
      />
      <v-spacer/>
      <v-btn @click="cancelEdit" :disabled="isSaving">Cancel</v-btn>
      <v-btn color="primary" @click="saveObject" :disabled="!areAllFieldsValid" :loading="isSaving && !isDeleting">{{saveText}}</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ButtonWithConfirmation from '@/components/ButtonWithConfirmation.vue'

export default {
  name: 'DynamicForm',
  components: { ButtonWithConfirmation, draggable },

  props: {
    fields: Array,
    object: Object,
    saveMethod: Function,
    deleteMethod: Function,
    focusIndex: Number,
    saveText: {
      type: String,
      default: 'Save'
    },
    successCallback: Function,
    cancelCallback: Function
  },

  data: () => ({
    nowUpdaterInterval: null,
    now: Date.now(),
    isSaving: false,
    isDeleting: false,
    mutableObject: null,
    isReady: false
  }),

  computed: {
    areAllFieldsValid () {
      let areAllFieldsValid = true;
      if (this.fields) {
        this.fields.forEach(field => {
          if (field.displayCondition && !field.displayCondition(this.mutableObject)) {
            // Not visible, ignore
          } else if (((field.rules && field.type === 'NamedCurrencyList') || (field.rules && field.type === 'AssetAllocationList')) && this.mutableObject[field.fieldName]) {
            let total = 0;
            this.mutableObject[field.fieldName].forEach(subField => {
              field.rules.forEach(rule => {
                if (field.type === 'NamedCurrencyList' && rule(subField.name) !== true)
                  areAllFieldsValid = false;
                if (field.type === 'AssetAllocationList') {
                  if (rule(subField.assetClassId) !== true)
                    areAllFieldsValid = false;
                } else {
                  if (rule(subField.amount) !== true)
                    areAllFieldsValid = false;
                }
                if (subField.amount)
                  total += subField.amount;
                if (subField.weight)
                  total += subField.weight;
              });
            });
            if (field.type === 'AssetAllocationList' && total !== 100)
              areAllFieldsValid = false;
          } else if (field.rules) {
            field.rules.forEach(rule => {
              if (rule(this.mutableObject[field.fieldName]) !== true)
                areAllFieldsValid = false;
            });
          }
        });
      }

      return areAllFieldsValid;
    }
  },

  methods: {
    cancelEdit() {
      if (this.cancelCallback)
        this.cancelCallback();
    },
    saveObject() {
      if (!this.areAllFieldsValid)
        return;

      this.isSaving = true;
      this.saveMethod(this.mutableObject)
        .then((response) => {
          if (this.successCallback)
            this.successCallback(response);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    deleteObject() {
      if (!this.deleteMethod)
        return;
      this.isSaving = true;
      this.isDeleting = true;
      return this.deleteMethod(this.mutableObject);
    }
  },

  mounted() {
    // Make sure it's a deep copy.
    this.mutableObject = JSON.parse(JSON.stringify(this.object));
    this.isReady = true;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    this.nowUpdaterInterval = setInterval(function () {
      me.now = Date.now() + 1000;
    }, 1000)
  },

  destroyed() {
    clearInterval(this.nowUpdaterInterval);
  }
}
</script>

<style scoped>
fieldset {
  border-radius: 4px;
  border-color: rgba(255, 255, 255, 0.24);
  border-width: thin;
  border-style: solid;
  padding: 8px
}

fieldset:hover {
  border-color: rgba(255, 255, 255);
}

.fieldset-disabled {
  border-color: rgba(255, 255, 255, 0.24) !important;
}

fieldset:focus-within {
  border-color: rgba(255, 255, 255);
}
</style>
