<template>
  <Card title="System Dashboard" icon="mdi-view-dashboard">
    <div class="timer">
      <p>Uptime: <span v-if="uptime.days">{{ uptime.days }} days, </span><span v-if="uptime.hours || uptime.days">{{ uptime.hours }} hours, </span><span v-if="uptime.minutes || uptime.hours || uptime.days">{{ uptime.minutes }} minutes, </span>{{ uptime.seconds }} seconds</p>
      <p>Host Updates Available: {{ hostUpdatesAvailable }}</p>
      <p>Version: {{ version }}</p>
      <p>Commit: {{ lastCommitMessage }}</p>
      <p>Errors: {{ errorCount }}</p>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card'
export default {
  name: 'AdminDashboard',
  components: { Card },
  data: () => ({
    hostUpdatesAvailable: null,
    startTime: null,
    uptime: {
      seconds: 0,
      minutes: 0,
      hours: 0,
      days: 0,
    },
    version: null,
    lastCommitMessage: null,
    errorCount: null,
    updateUptimeInterval: null,
    updateErrorCountInterval: null,
  }),
  methods: {
    updateUptime() {
      const now = new Date().getTime();

      const diffInSeconds = Math.floor((now - this.startTime.getTime()) / 1000)

      this.uptime.days = Math.floor(diffInSeconds / (24 * 60 * 60))
      this.uptime.hours = Math.floor((diffInSeconds % (24 * 60 * 60)) / (60 * 60))
      this.uptime.minutes = Math.floor((diffInSeconds % (60 * 60)) / 60)
      this.uptime.seconds = diffInSeconds % 60
    }
  },
  mounted () {
    this.$api.admin.getStartTime().then(response => {
      this.startTime = new Date(response);
      this.updateUptime();

      this.updateUptimeInterval = setInterval(() => {
        this.updateUptime();
      }, 1000);

      this.$api.admin.getErrorCount().then(response => {
        this.errorCount = response;
      });

      this.updateErrorCountInterval = setInterval(() =>
        this.$api.admin.getErrorCount().then(response => {
          this.errorCount = response;
        }), 5000);
    });

    this.$api.admin.getHostUpdatesAvailable().then(response => {
      this.hostUpdatesAvailable = response;
    });

    this.$api.admin.getVersion().then(response => {
      this.version = response;
    });

    this.$api.admin.getLastCommitMessage().then(response => {
      this.lastCommitMessage = response;
    });
  },
  unmounted() {
    if (this.updateUptimeInterval)
      clearInterval(this.updateUptimeInterval);
    if (this.updateErrorCountInterval)
      clearInterval(this.updateErrorCountIntervals);
  }
}
</script>

<style scoped>

</style>
