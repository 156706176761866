<template>
  <div>
    <ActionItemsCard type="financialmodule" :id="financialModule.id" wide/>
    <Card
      v-if="uncategorizedEnvelope && (uncategorizedEnvelope.transactionCount > 0 || (uncategorizedTransactions))"
      icon="mdi-cash-multiple" :title="uncategorizedEnvelope.transactionCount + ' Uncategorized Transaction' + (uncategorizedEnvelope.transactionCount === 1 ? '' : 's') + ' ' + formatMoney(uncategorizedEnvelope.amount)"
      :actions="[{icon: 'mdi-refresh', click: this.searchForUncategorizedTransactions}]"
      wide
      :style="uncategorizedTransactions && uncategorizedTransactions.length < 1 ? 'display: none;' : ''"
    >
      <TransactionTable
        v-if="uncategorizedTransactions"
        :transactions="uncategorizedTransactions"
        :financialModule="financialModule"
        :accounts="accounts"
        :envelopes="envelopes"
        :mySecurities="mySecurities"
        :incomeSources="incomeSources"
        :showQuickAssignEnvelopeButton="true"
        :envelopeIdToAdd="uncategorizedEnvelope.id"
      />
    </Card>

    <Card v-if="accounts && accounts.length > 0 && accounts.length <= 3" icon="mdi-currency-usd" :title="financialModule.name">
      <!--    <div v-if="accounts && accounts.length <= 3" class="subtitle-2">-->
      <div>
        <!--    <div v-if="accounts && accounts.length <= 3">-->
        Welcome to your Financial App!<br/>
        Each financial app consists of multiple accounts and each account has a purpose.
        Cash, checking, and credit accounts can be used for Envelopes. All transactions associated with these accounts can be assigned to an envelope in the
        <v-btn x-small rounded color="primary" :to="'/h/' + this.household.id + '/f/' + this.financialModule.id + '/envelopes'"><v-icon left>mdi-email-multiple</v-icon> Envelopes</v-btn> view.
        We've created a few accounts to get you started. A Cash account represents cash held in the real world, a Transfer account is used to transfer money between other accounts,
        and a Property account set up to hold assets like real estate or vehicles.
        You can also specify the last 4 digits of your account number or an official name to help with imports but this is optional.
        To create or manage your accounts, navigate to the
        <v-btn x-small rounded color="primary" :to="'/h/' + this.household.id + '/f/' + this.financialModule.id + '/accounts'"><v-icon left>mdi-bank</v-icon> Accounts</v-btn> view.
        <br/>
        Each account holds transactions. These transactions can be created and managed on the
        <v-btn x-small rounded color="primary" :to="'/h/' + this.household.id + '/f/' + this.financialModule.id + '/transactions'"><v-icon left>mdi-cash-multiple</v-icon> Transactions</v-btn> view.
        When you create an account designated for Envelopes, you'll set an initial start date.
        Any imported transactions dated before your account's initial transaction's date will be added to an envelope called "Pre-Envelopes" while imported transactions dated on or after will be placed in "Uncategorized".
        Transactions in the "Uncategorized" envelope should be reviewed and placed in the correct envelope. Based on past transactions, the system may recommend an envelope.
        <br/>
        To sync transactions, we recommend downloading CSV exports from your financial institutions and uploading them to your Financial App.
        You can do this by dragging the CSV on to any Financial App view or by uploading the files on the
        <v-btn x-small rounded color="primary" :to="'/h/' + this.household.id + '/f/' + this.financialModule.id + '/sync'"><v-icon left>mdi-autorenew</v-icon> Sync</v-btn> view.
        For your initial import, please download as many transactions as possible. For subsequent imports, 30 days of data is sufficient.
        <br/>
        You can also create an Email Hook address to create pending transactions in real-time.
        <br/>
        <br/>
        For the next steps, we recommend:
        <ul>
          <li>Create a few accounts</li>
          <li>Import transactions</li>
          <li>Create transactions or adjust your initial transaction so the balance matches your financial institution</li>
          <li>Create envelopes and organize recent transactions</li>
        </ul>
        <br/>
        In the future, we'll replace this message with a pretty dashboard. Happy enveloping!
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import ActionItemsCard from '@/hoamodules/ActionItemsCard.vue'
import TransactionTable from '@/hoamodules/financial/TransactionTable.vue'
export default {
  name: 'Dashboard',
  components: { TransactionTable, ActionItemsCard, Card },
  props: ['household', 'financialModule', 'accounts', 'envelopes', 'mySecurities', 'incomeSources'],

  data: () => ({
    subscription: null,
    resolved: false,
    uncategorizedTransactions: null,
  }),
  methods: {
    searchForUncategorizedTransactions() {
      this.uncategorizedTransactions = null;
      this.$api.financial_transaction.getTransactions(this.financialModule.id, [], [this.financialModule.id],
        null, null, '', 0).then(transactions => {
        transactions.forEach(newTransaction => {
          if (newTransaction.envelopes)
            newTransaction.newEnvelopes = JSON.parse(JSON.stringify(newTransaction.envelopes));
        });
        transactions.sort((a, b) => b.date.localeCompare(a.date));
        this.uncategorizedTransactions = transactions;
      });
    },
  },
  mounted() {
    this.$emit('breadcrumbs', []);
    this.searchForUncategorizedTransactions()
  },
  computed: {
    uncategorizedEnvelope() {
      let uncategorizedEnvelope = null;
      this.envelopes.forEach(envelope => {
        if (envelope.id === envelope.financialModuleId) {
          uncategorizedEnvelope = envelope;
        }
      });
      return uncategorizedEnvelope;
    },
  }
}
</script>

<style scoped>

</style>
