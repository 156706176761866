<template>
  <div>
    <div id="dropZone" :style="'visibility: ' + (showDropZone ? 'visible' : 'hidden')">
        Drop files here
<!--      <span id="dropZoneInner">-->
<!--      </span>-->
    </div>
    <div v-if="financialModule" :key="financialModule.id">
      <router-view
        v-if="envelopes && accounts"
        :household="household"
        :financialModule="financialModule"
        :envelopes="envelopes"
        :accounts="accounts"
        :mySecurities="mySecurities"
        :investmentPurposes="investmentPurposes"
        :incomeSources="incomeSources"
        v-on:breadcrumbs="setBreadcrumbs($event)"
        v-on:uploadFile="fileUpload($event)"
      />
    </div>
    <div v-else-if="resolved">
      <Card title="Not Found" icon="mdi-alert">
        <div>Sorry, we couldn't find that financial module.</div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card'
export default {
  name: 'FinancialModule',
  components: { Card },
  props: ['household', 'financialModuleId', 'listId'],

  data: () => ({
    financialModule: null,
    childBreadcrumbs: [],
    resolved: false,
    subscription: null,
    envelopeSubscription: null,
    envelopes: null,
    accountSubscription: null,
    accounts: null,
    investmentPurposeSubscription: null,
    investmentPurposes: null,
    showDropZone: false,
    dropZoneElement: null,

    mySecurities: null,
    mySecuritiesSubscription: null,

    incomeSources: null,
    incomeSourcesSubscription: null,

  }),

  methods: {
    setBreadcrumbs(childBreadcrumbs) {
      this.childBreadcrumbs = childBreadcrumbs;
      this.updateBreadcrumbs();
    },
    updateBreadcrumbs() {
      const breadcrumbs = [];
      if (this.financialModule) {
        breadcrumbs.push({
            text: this.financialModule.name,
            icon: 'mdi-currency-usd',
            to: '/h/' + this.household.id + '/f/' + this.financialModule.id
          }
        );

        if (this.childBreadcrumbs && this.childBreadcrumbs.forEach)
          this.childBreadcrumbs.forEach((crumb) => {
            // crumb.to =
            breadcrumbs.push(crumb)
          });
      }

      this.$emit('breadcrumbs', breadcrumbs);
    },
    resubscribe(financialModuleId) {
      if (this.subscription)
        this.$api.unsubscribe(this.subscription);
      if (this.envelopeSubscription)
        this.$api.unsubscribe(this.envelopeSubscription);
      if (this.accountSubscription)
        this.$api.unsubscribe(this.accountSubscription);
      if (this.investmentPurposeSubscription)
        this.$api.unsubscribe(this.investmentPurposeSubscription);
      if (this.mySecuritiesSubscription)
        this.$api.unsubscribe(this.mySecuritiesSubscription);
      if (this.incomeSourcesSubscription)
        this.$api.unsubscribe(this.incomeSourcesSubscription);

      this.resolved = false;
      this.subscription = this.$api.financial.subscribeToFinancialModule(financialModuleId, financialModule => {
        const menuItems = [];
        if (financialModule) {
          menuItems.push({
            title: financialModule.name,
            icon: 'mdi-currency-usd',
            to: '/h/' + this.household.id + '/f/' + financialModule.id,
            color: financialModule.color,
          });

          // listHolder.lists.forEach(list => {
          //   menuItems.push({
          //     title: list.name,
          //     icon: 'mdi-alpha-' + list.name.substr(0, 1).toLowerCase() + '-box',
          //     to: '/h/' + this.household.id + '/list/' + listHolder.id + '/' + list.id
          //   });
          // });

          menuItems.push({
            title: 'Envelopes',
            icon: 'mdi-email-multiple',
            to: '/h/' + this.household.id + '/f/' + financialModule.id + '/envelopes',
            exact: false
          });

          menuItems.push({
            title: 'Transactions',
            icon: 'mdi-cash-multiple',
            to: '/h/' + this.household.id + '/f/' + financialModule.id + '/transactions'
          });

          menuItems.push({
            title: 'Investing',
            icon: 'mdi-finance',
            to: '/h/' + this.household.id + '/f/' + financialModule.id + '/investing'
          });

          menuItems.push({
            title: 'Net Worth',
            icon: 'mdi-egg',
            to: '/h/' + this.household.id + '/f/' + financialModule.id + '/networth'
          });

          menuItems.push({
            title: 'Sync',
            icon: 'mdi-sync',
            to: '/h/' + this.household.id + '/f/' + financialModule.id + '/sync'
          });

          menuItems.push({
            title: 'Accounts',
            icon: 'mdi-bank',
            to: '/h/' + this.household.id + '/f/' + financialModule.id + '/accounts'
          });

          menuItems.push({
            title: 'Settings',
            icon: 'mdi-cog',
            to: '/h/' + this.household.id + '/f/' + financialModule.id + '/settings'
          });
        }

        this.$emit('menuItems', menuItems);
        this.financialModule = financialModule;
        this.updateBreadcrumbs();
        this.resolved = true;
      }).unsubscribed.then(() => {
        this.financialModule = null;
        this.resolved = true;
      });

      this.envelopes = [];
      this.envelopeSubscription = this.$api.financial_transaction.subscribeToEnvelopes(financialModuleId, newObject => {
        let index = null;
        for (let i = 0; i < this.envelopes.length; i++)
          if (this.envelopes[i].id === newObject.id)
            index = i;
        if (index !== null) {
          // this.envelopes[index] = newObject; // We must splice so vue will see the changes
          if (newObject.deletedTimestamp)
            this.envelopes.splice(index, 1);
          else
            this.envelopes.splice(index, 1, newObject);
        } else {
          this.envelopes.push(newObject);
        }
        this.envelopes.sort((a, b) => a.position - b.position);
      }).unsubscribed.then(() => {
        this.envelopes = null;
      });

      this.accounts = [];
      this.accountSubscription = this.$api.financial_account.subscribeToAccounts(financialModuleId, newObject => {
        if (newObject.id === newObject.financialModuleId)
          newObject.disabled = true;

        let index = null;
        for (let i = 0; i < this.accounts.length; i++)
          if (this.accounts[i].id === newObject.id)
            index = i;
        if (index !== null) {
          // this.envelopes[index] = newObject; // We must splice so vue will see the changes
          this.accounts.splice(index, 1, newObject);
        } else {
          this.accounts.push(newObject);
        }
        this.accounts.sort((a, b) => a.position - b.position);
      }).unsubscribed.then(() => {
        this.accounts = null;
      });

      this.investmentPurposes = [];
      this.investmentPurposeSubscription = this.$api.financial_investments.subscribeToInvestmentPurposes(financialModuleId, newObject => {
        let index = null;
        for (let i = 0; i < this.investmentPurposes.length; i++)
          if (this.investmentPurposes[i].id === newObject.id)
            index = i;
        if (index !== null) {
          // this.envelopes[index] = newObject; // We must splice so vue will see the changes
          this.investmentPurposes.splice(index, 1, newObject);
        } else {
          this.investmentPurposes.push(newObject);
        }
        this.investmentPurposes.sort((a, b) => a.position - b.position);
      }).unsubscribed.then(() => {
        this.investmentPurposes = null;
      });

      this.mySecurities = [];
      this.mySecuritiesSubscription = this.$api.financial_investments.subscribeToFinancialModuleSecurities(financialModuleId, newObject => {
        let index = null;
        for (let i = 0; i < this.mySecurities.length; i++)
          if (this.mySecurities[i].id === newObject.id)
            index = i;
        if (index !== null) {
          // this.envelopes[index] = newObject; // We must splice so vue will see the changes
          this.mySecurities.splice(index, 1, newObject);
        } else {
          this.mySecurities.push(newObject);
        }
      }).unsubscribed.then(() => {
        this.mySecurities = null;
      });

      this.incomeSources = [];
      this.incomeSourcesSubscription = this.$api.financial_income_source.subscribeToIncomeSourcesManageArray(financialModuleId, this.incomeSources, true);
    },
    dragEnter(event) {
      if (!this.showDropZone) {
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          if (event.dataTransfer.items[i].kind === 'file')
            this.showDropZone = true;
        }
      }

      // if (!event.fromElement)
      //   this.showDropZone = true;
    },
    dragLeave(event) {
      this.showDropZone = false;
    },
    allowDrag(event) {
      let allowDrag = this.showDropZone;
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        if (event.dataTransfer.items[i].kind === 'file')
          allowDrag = true;
      }
      if (allowDrag) {
        event.dataTransfer.dropEffect = 'copy';
        event.preventDefault();
      }
    },
    fileUpload(file) {
      if (!file)
        return;

      const textFile = !file.type || file.type.startsWith("text");
      const smallEnough = file.size < (10 * 1024 * 1024);

      if (!textFile) {
        this.showError("No parsable file detected.");
      }

      if (!smallEnough) {
        this.showError("File is too large.");
      }

      if (textFile && smallEnough) {
        const reader = new FileReader();
        reader.onload = () => {
          // const errorLimit = 100;
          // let errorCount = 0;
          // for (let ci = 0; ci < reader.result.length && (errorCount < errorLimit); ci++) {
          //   if (reader.result.charCodeAt(ci) >= 65533) {
          //     errorCount++;
          //     // for (let cierror = 0; cierror < ci; cierror++)
          //     //   console.dir(cierror + ": " + reader.result.charAt(cierror) + " - " + reader.result.charCodeAt(cierror));
          //   }
          // }
          // if (errorCount < errorLimit) {
          //   console.dir(reader.result);
          //   this.$api.financial_transaction_import.uploadImportFile({
          //     financialModuleId: this.financialModule.id,
          //     source: file.name,
          //     fileType: file.type,
          //     fileSize: file.size,
          //   }, reader.result);
          //   if (this.$route.path !== '/h/' + this.household.id + '/f/' + this.financialModule.id + '/sync')
          //     this.$router.push('/h/' + this.household.id + '/f/' + this.financialModule.id + '/sync');
          // } else {
          //   this.showError("Invalid characters detected.");
          // }
          this.$api.financial_transaction_import.uploadImportFile({
            financialModuleId: this.financialModule.id,
            source: 'File: ' + file.name,
            fileType: file.type,
            fileSize: file.size,
          }, reader.result);
          if (this.$route.path !== '/h/' + this.household.id + '/f/' + this.financialModule.id + '/sync')
            this.$router.push('/h/' + this.household.id + '/f/' + this.financialModule.id + '/sync');
        };

        reader.onerror = () => {
          this.showError("Error reading file.")
        }

        reader.readAsBinaryString(file);
      }
    },
    fileDrop(event) {
      event.preventDefault();

      this.showDropZone = false;

      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const entry = event.dataTransfer.items[i].webkitGetAsEntry();
        if (entry && entry.isFile) {
          entry.file((file) => {
            this.fileUpload(file);
          }, () => {
            this.showError("Error reading file.")
          });
        }
      }
    },
    showError(message) {
      this.$emit("showError", message);
    }

  },

  watch: {
    financialModuleId(financialModuleId) {
      this.resubscribe(financialModuleId);
    },
  },

  mounted() {
    this.resubscribe(this.financialModuleId);
    this.dropZoneElement = document.getElementById('dropZone');

    window.addEventListener('dragenter', this.dragEnter);
    this.dropZoneElement.addEventListener('dragenter', this.allowDrag);
    this.dropZoneElement.addEventListener('dragover', this.allowDrag);
    this.dropZoneElement.addEventListener('dragleave', this.dragLeave);
    this.dropZoneElement.addEventListener('drop', this.fileDrop);
  },

  destroyed() {
    window.removeEventListener('dragenter', this.dragEnter);
    if (this.dropZoneElement) {
      this.dropZoneElement.removeEventListener('dragenter', this.allowDrag);
      this.dropZoneElement.removeEventListener('dragover', this.allowDrag);
      this.dropZoneElement.removeEventListener('dragleave', this.dragLeave);
      this.dropZoneElement.removeEventListener('drop', this.fileDrop);
    }

    if (this.subscription)
      this.$api.unsubscribe(this.subscription);
    if (this.envelopeSubscription)
      this.$api.unsubscribe(this.envelopeSubscription);
    if (this.accountSubscription)
      this.$api.unsubscribe(this.accountSubscription);
    if (this.investmentPurposeSubscription)
      this.$api.unsubscribe(this.investmentPurposeSubscription);
    if (this.mySecuritiesSubscription)
      this.$api.unsubscribe(this.mySecuritiesSubscription);
    if (this.incomeSourcesSubscription)
      this.$api.unsubscribe(this.incomeSourcesSubscription);
  }
}
</script>

<style scoped>
#dropZone {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0.5;
  background: gray;

  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  font-size: xxx-large;

  border: 10px dashed #bbb;
}

#dropZoneInner {
  border: 6px dashed #bbb;

  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 50px;
  width: calc(100% - 100px);
  height: calc(100% - 100px);

  font-weight: bold;
  font-size: xxx-large;
  /*font: 21pt bold arial;*/
}
</style>
