export class Subscription {
  subscriptionId: number;
  unsubscribed: Promise<string>;

  constructor(subscriptionId: number, unsubscribed: Promise<string>) {
    this.subscriptionId = subscriptionId;
    this.unsubscribed = unsubscribed;
  }
}

export default class API {
  private readonly secure: boolean;
  private readonly basePath: string;
  private errorCallback: (message: string) => void;
  private onCloseCallback: (message: string | undefined) => void;
  private onConnectCallback: () => void;
  private pendingApiEventCountCallback: (count: number) => void;
  private pendingApiEvents = 0;
  private websocket: WebSocket | null = null;
  private nextWebsocketId = 0;
  private subscriptionHandlers = {};
  private subscriptionResolves = {};
  private rpcPromiseResolves = {};
  private rpcPromiseRejects = {};
  private heartbeat: number | null = null;

  registerErrorHandler(errorCallback: (message: string) => void) {
    this.errorCallback = errorCallback;
  }

  emitError(message: string) {
    if (this.errorCallback)
      this.errorCallback(message);
  }

  registerOnCloseHandler(onCloseCallback: (message: string | undefined) => void) {
    this.onCloseCallback = onCloseCallback;
  }

  registerOnConnectHandler(onConnectCallback: () => void) {
    this.onConnectCallback = onConnectCallback;
  }

  registerPendingApiEventCountHandler(pendingApiEventCountCallback: (count: number) => void) {
    this.pendingApiEventCountCallback = pendingApiEventCountCallback;
  }

  // eslint-disable-next-line
  serialize(object: any): string {
    return JSON.stringify(object, (key, value) => {
      if (value !== null) return value
    });
  }

  connectWebsocket() {
    this.websocket = new WebSocket((this.secure ? "wss://" : "ws://") + this.basePath + "/api/ws");

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const api = this;
    this.websocket.onerror = function(error) {
      console.log(error);
      api.handleWebsocketClose();
    };

    this.websocket.onclose = function (message) {
      let messageText;
      if (message.reason) {
        messageText = message.reason;
      }
      if (api.heartbeat !== null) {
        clearInterval(api.heartbeat);
      }
      api.handleWebsocketClose(messageText)
    };

    this.websocket.onopen = function() {
      console.dir("connected!");
      api.heartbeat = setInterval(() => {
        if (api.websocket)
          api.websocket.send("h");
      }, 25000);
      api.onConnectCallback();
    };

    this.websocket.onmessage = function(event) {
      const serverMessage: ServerMessage = JSON.parse(event.data);
      if (serverMessage.publish) {
        // eslint-disable-next-line
        // @ts-ignore
        if (api.subscriptionHandlers[serverMessage.publish.subscriptionId]) {
          // eslint-disable-next-line
          // @ts-ignore
          api.subscriptionHandlers[serverMessage.publish.subscriptionId](serverMessage.publish.data);
        }
      }

      if (serverMessage.unsubscribed) {
        // Let's not handle this reason globally
        // api.errorCallback(serverMessage.unsubscribed.reason);
        // eslint-disable-next-line
        // @ts-ignore
        if (api.subscriptionResolves[serverMessage.unsubscribed.subscriptionId]) {
          // eslint-disable-next-line
          // @ts-ignore
          api.subscriptionResolves[serverMessage.unsubscribed.subscriptionId](serverMessage.unsubscribed.reason);
        }
      }

      if (serverMessage.response) {
        if (serverMessage.response.success) {
          // eslint-disable-next-line
          // @ts-ignore
          if (api.rpcPromiseResolves[serverMessage.response.requestId]) {
            // eslint-disable-next-line
            // @ts-ignore
            api.rpcPromiseResolves[serverMessage.response.requestId](serverMessage.response.data);
          }
        } else {
          api.errorCallback(serverMessage.response.errorMessage);
          // eslint-disable-next-line
          // @ts-ignore
          if (api.rpcPromiseRejects[serverMessage.response.requestId]) {
            // eslint-disable-next-line
            // @ts-ignore
            api.rpcPromiseRejects[serverMessage.response.requestId](new Error(serverMessage.response.errorMessage));
          }
        }

        // eslint-disable-next-line
        // @ts-ignore
        delete api.rpcPromiseResolves[serverMessage.response.requestId];
        // eslint-disable-next-line
        // @ts-ignore
        delete api.rpcPromiseRejects[serverMessage.response.requestId];

        api.pendingApiEventCountCallback(--api.pendingApiEvents);
      }
    }
  }

  handleWebsocketClose(message: string | undefined = undefined) {
    console.dir("handleWebsocketClose");
    console.dir(message);
    this.websocket = null;
    this.onCloseCallback(message);
  }

  // eslint-disable-next-line
  subscribe(className: string, id: string, handler: (object: any) => void): Subscription {
    const subscriptionId: number = this.nextWebsocketId;
    this.nextWebsocketId = this.nextWebsocketId + 1;

    // eslint-disable-next-line
    const wsPromise = new Promise<string>((resolve, reject) => {
      // eslint-disable-next-line
      // @ts-ignore
      this.subscriptionResolves[subscriptionId] = resolve;
    });

    if (this.websocket) {
      // eslint-disable-next-line
      // @ts-ignore
      this.subscriptionHandlers[subscriptionId] = handler;
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line
      this.websocket.send(this.serialize(new ClientMessage(null, new WSSubscribe(subscriptionId, className, id), null)));
    }
    return new Subscription(subscriptionId, wsPromise);
  }

  unsubscribe(subscription: Subscription) {
    // eslint-disable-next-line
    // @ts-ignore
    delete this.subscriptionHandlers[subscription.subscriptionId];

    if (this.websocket) {
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line
      this.websocket.send(this.serialize(new ClientMessage(null, null, new WSUnsubscribe(subscription.subscriptionId))));
    }
  }

  // eslint-disable-next-line
  webSocketRpc(appName: string, methodName: string, methodArguments: object): Promise<any> {
    const requestId = this.nextWebsocketId;
    this.nextWebsocketId = this.nextWebsocketId + 1;

    const rpcPromise = new Promise((resolve, reject) => {
      // eslint-disable-next-line
      // @ts-ignore
      this.rpcPromiseResolves[requestId] = resolve;
      // eslint-disable-next-line
      // @ts-ignore
      this.rpcPromiseRejects[requestId] = reject;

      this.pendingApiEventCountCallback(++this.pendingApiEvents);
    });

    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    this.websocket.send(this.serialize(new ClientMessage(new WSRequest(requestId, appName, methodName, methodArguments), null, null)));

    return rpcPromise;
  }

// eslint-disable-next-line
  rpc(appName: string, methodName: string, methodArguments: object): Promise<any> {
    if (this.websocket)
      return this.webSocketRpc(appName, methodName, methodArguments);
    else
      return this.httpRpc(appName, methodName, methodArguments);
  }

  // eslint-disable-next-line
  httpRpc(appName: string, methodName: string, methodArguments: object): Promise<any> {
    let request: XMLHttpRequest | null = null;
    if (window.XMLHttpRequest) { // Mozilla, Safari, ...
      request = new XMLHttpRequest();
    } else if (window.ActiveXObject) { // IE
      try {
        // eslint-disable-next-line no-undef
        request = new ActiveXObject('Msxml2.XMLHTTP');
      } catch (e) {
        try {
          // eslint-disable-next-line no-undef
          request = new ActiveXObject('Microsoft.XMLHTTP');
        } catch (e) {
        }
      }
    } else {
      request = null;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const api = this;
    if (request !== null) {
      const finalRequest: XMLHttpRequest = request;
      return new Promise((resolve, reject) => {
        finalRequest.onreadystatechange = function () {
          if (finalRequest.readyState === 4) { // done
            try {
              const response: RPCResponse = JSON.parse(finalRequest.responseText);
              if (response.success) {
                resolve(response.response);
              } else if (response.errorMessage) {
                api.errorCallback(response.errorMessage);
                reject(new Error(response.errorMessage));
              } else if (finalRequest.status === 401) {
                api.errorCallback("Unauthorized");
                reject(new Error("Unauthorized"));
              } else {
                api.errorCallback("Unknown " + finalRequest.status);
                reject(new Error("Unknown " + finalRequest.status));
              }
            } catch (exception) {
              // api.errorCallback("Unknown " + finalRequest.status);
              reject(new Error("Unknown " + finalRequest.status));
            }
          }
        };
        finalRequest.open("POST", "/api/rpc/" + appName + "/" + methodName);
        finalRequest.setRequestHeader("Content-Type", "application/json");
        finalRequest.send(this.serialize(methodArguments));
      });
    }

    return new Promise((resolve, reject) => {
      reject(new Error("JS httpRpc unknown error"));
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      let request: XMLHttpRequest | null = null;
      if (window.XMLHttpRequest) { // Mozilla, Safari, ...
        request = new XMLHttpRequest();
      } else if (window.ActiveXObject) { // IE
        try {
          // eslint-disable-next-line no-undef
          request = new ActiveXObject('Msxml2.XMLHTTP');
        } catch (e) {
          try {
            // eslint-disable-next-line no-undef
            request = new ActiveXObject('Microsoft.XMLHTTP');
          } catch (e) {
          }
        }
      }

      if (request !== null) {
        const finalRequest: XMLHttpRequest = request;
        finalRequest.onreadystatechange = function () {
          if (finalRequest.readyState === 4) { // done
            if (finalRequest.status === 200) { // complete
              resolve(true);
            } else {
              reject(new Error());
            }
          }
        };
        request.open("GET", "/api/logout");
        request.send();
      }
    });
  }

  signIn(username: string, password: string, type?: string) {
    let request: XMLHttpRequest | null = null;
    if (window.XMLHttpRequest) { // Mozilla, Safari, ...
      request = new XMLHttpRequest();
    } else if (window.ActiveXObject) { // IE
      try {
        // eslint-disable-next-line no-undef
        request = new ActiveXObject('Msxml2.XMLHTTP');
      } catch (e) {
        try {
          // eslint-disable-next-line no-undef
          request = new ActiveXObject('Microsoft.XMLHTTP');
        } catch (e) {
        }
      }
    }

    if (request !== null) {
      const finalRequest: XMLHttpRequest = request;
      return new Promise((resolve, reject) => {
        finalRequest.onreadystatechange = function () {
          if (finalRequest.readyState === 4) { // done
            if (finalRequest.status === 200) { // complete
              resolve(true);
            } else {
              reject(new Error());
            }
          }
        };

        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        finalRequest.open("POST", "/api/login");
        if (type)
          finalRequest.setRequestHeader("type", type);
        finalRequest.send(formData);
      });
    }
  }

  constructor(secure: boolean, basePath: string) {
    this.secure = secure;
    this.basePath = basePath;
    // eslint-disable-next-line
    this.errorCallback = message => {};
    // eslint-disable-next-line
    this.onCloseCallback = message => {};
    // eslint-disable-next-line
    this.onConnectCallback = () => {};
    // eslint-disable-next-line
    this.pendingApiEventCountCallback = () => {};

    // START GENERATED FUNCTIONS
    /* eslint-disable */
    this.action_item = new RPCHandler_action_item(this);
    this.admin = new RPCHandler_admin(this);
    this.ai = new RPCHandler_ai(this);
    this.asset_value = new RPCHandler_asset_value(this);
    this.calendar = new RPCHandler_calendar(this);
    this.cloud = new RPCHandler_cloud(this);
    this.financial = new RPCHandler_financial(this);
    this.financial_account = new RPCHandler_financial_account(this);
    this.financial_income_source = new RPCHandler_financial_income_source(this);
    this.financial_investments = new RPCHandler_financial_investments(this);
    this.financial_transaction = new RPCHandler_financial_transaction(this);
    this.financial_transaction_import = new RPCHandler_financial_transaction_import(this);
    this.household = new RPCHandler_household(this);
    this.list = new RPCHandler_list(this);
    this.module = new RPCHandler_module(this);
    this.newton = new RPCHandler_newton(this);
    this.notifications = new RPCHandler_notifications(this);
    this.passkey = new RPCHandler_passkey(this);
    this.permission = new RPCHandler_permission(this);
    this.petal = new RPCHandler_petal(this);
    this.user = new RPCHandler_user(this);
    this.webrepo = new RPCHandler_webrepo(this);
  }

  arrayHandler(objects: any[], addNew: boolean) {
    return (newObject: any) => {
      let index = null;
      for (let i = 0; i < objects.length; i++)
        if (objects[i].id === newObject.id)
          index = i;
      if (index !== null) {
        if (newObject.deletedTimestamp)
          objects.splice(index, 1);
        else
          objects.splice(index, 1, newObject);
      } else if (addNew) {
        objects.push(newObject);
      }
    }
  }

  public action_item: RPCHandler_action_item;
  public admin: RPCHandler_admin;
  public ai: RPCHandler_ai;
  public asset_value: RPCHandler_asset_value;
  public calendar: RPCHandler_calendar;
  public cloud: RPCHandler_cloud;
  public financial: RPCHandler_financial;
  public financial_account: RPCHandler_financial_account;
  public financial_income_source: RPCHandler_financial_income_source;
  public financial_investments: RPCHandler_financial_investments;
  public financial_transaction: RPCHandler_financial_transaction;
  public financial_transaction_import: RPCHandler_financial_transaction_import;
  public household: RPCHandler_household;
  public list: RPCHandler_list;
  public module: RPCHandler_module;
  public newton: RPCHandler_newton;
  public notifications: RPCHandler_notifications;
  public passkey: RPCHandler_passkey;
  public permission: RPCHandler_permission;
  public petal: RPCHandler_petal;
  public user: RPCHandler_user;
  public webrepo: RPCHandler_webrepo;
}

// action_item
class RPCHandler_action_item {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public subscribeToActionItems(id: string, handler: (uiactionitem: UIActionItem) => void): Subscription {
    return this.api.subscribe('UIActionItem', 'parent:' + id, handler);
  }
  public subscribeToActionItemsManageArray(id: string, objects: UIActionItem[], addNew: boolean = true): Subscription {
    return this.api.subscribe('UIActionItem', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
}

// admin
class RPCHandler_admin {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public add(x: number, y: number): Promise<number> {
    const parameters = {'x': x, 'y': y};
    // @ts-ignore
    return this.api.rpc('admin', 'add', parameters);
  }
  public backendUpperCase(message: string): Promise<string> {
    const parameters = {'message': message};
    // @ts-ignore
    return this.api.rpc('admin', 'backendUpperCase', parameters);
  }
  public getErrorCount(): Promise<number> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('admin', 'getErrorCount', parameters);
  }
  public getHostUpdatesAvailable(): Promise<number> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('admin', 'getHostUpdatesAvailable', parameters);
  }
  public getLastCommitMessage(): Promise<string> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('admin', 'getLastCommitMessage', parameters);
  }
  public getStartTime(): Promise<string /*Instant*/> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('admin', 'getStartTime', parameters);
  }
  public getVersion(): Promise<string> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('admin', 'getVersion', parameters);
  }
}

// ai
class RPCHandler_ai {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public clearRecentConversation(id: string): Promise<void> {
    const parameters = {'id': id};
    // @ts-ignore
    return this.api.rpc('ai', 'clearRecentConversation', parameters);
  }
  public createNewAI(name: string, aiAccess: any /*java.util.Map*/): Promise<AIPrincipal> {
    const parameters = {'name': name, 'aiAccess': aiAccess};
    // @ts-ignore
    return this.api.rpc('ai', 'createNewAI', parameters);
  }
  public deleteAIMemory(aiMemory: AIMemory): Promise<void> {
    const parameters = {'aiMemory': aiMemory};
    // @ts-ignore
    return this.api.rpc('ai', 'deleteAIMemory', parameters);
  }
  public getAI(id: string): Promise<AIPrincipal> {
    const parameters = {'id': id};
    // @ts-ignore
    return this.api.rpc('ai', 'getAI', parameters);
  }
  public getAIMemories(id: string, handler: (aimemory: AIMemory) => void): Subscription {
    return this.api.subscribe('AIMemory', 'parent:' + id, handler);
  }
  public getAIMemoriesManageArray(id: string, objects: AIMemory[], addNew: boolean = true): Subscription {
    return this.api.subscribe('AIMemory', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
}

// asset_value
class RPCHandler_asset_value {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

}

// calendar
class RPCHandler_calendar {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public createCalendar(calendarHolderId: string, name: string, description: string, color: string, url: string): Promise<Calendar> {
    const parameters = {'calendarHolderId': calendarHolderId, 'name': name, 'description': description, 'color': color, 'url': url};
    // @ts-ignore
    return this.api.rpc('calendar', 'createCalendar', parameters);
  }
  public createCalendarHolder(householdId: string, name: string): Promise<CalendarHolder> {
    const parameters = {'householdId': householdId, 'name': name};
    // @ts-ignore
    return this.api.rpc('calendar', 'createCalendarHolder', parameters);
  }
  public deleteCalendar(calendarId: string): Promise<void> {
    const parameters = {'calendarId': calendarId};
    // @ts-ignore
    return this.api.rpc('calendar', 'deleteCalendar', parameters);
  }
  public saveCalendar(calendar: Calendar): Promise<void> {
    const parameters = {'calendar': calendar};
    // @ts-ignore
    return this.api.rpc('calendar', 'saveCalendar', parameters);
  }
  public syncCalendar(calendarId: string): Promise<void> {
    const parameters = {'calendarId': calendarId};
    // @ts-ignore
    return this.api.rpc('calendar', 'syncCalendar', parameters);
  }
  public subscribeToCalendarEvents(id: string, handler: (calendarevent: CalendarEvent) => void): Subscription {
    return this.api.subscribe('CalendarEvent', 'parent:' + id, handler);
  }
  public subscribeToCalendarEventsManageArray(id: string, objects: CalendarEvent[], addNew: boolean = true): Subscription {
    return this.api.subscribe('CalendarEvent', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
  public subscribeToCalendarHolder(id: string, handler: (calendarholder: CalendarHolder) => void): Subscription {
    return this.api.subscribe('CalendarHolder', id, handler);
  }
  public subscribeToCalendars(id: string, handler: (calendar: Calendar) => void): Subscription {
    return this.api.subscribe('Calendar', 'parent:' + id, handler);
  }
  public subscribeToCalendarsManageArray(id: string, objects: Calendar[], addNew: boolean = true): Subscription {
    return this.api.subscribe('Calendar', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
}

// cloud
class RPCHandler_cloud {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public createCloudIntegration(householdId: string, name: string, type: CloudIntegrationType, username: string, password: string): Promise<CloudIntegration> {
    const parameters = {'householdId': householdId, 'name': name, 'type': type, 'username': username, 'password': password};
    // @ts-ignore
    return this.api.rpc('cloud', 'createCloudIntegration', parameters);
  }
}

// financial
class RPCHandler_financial {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public createFinancialModule(householdId: string, name: string, createEnvelopes: boolean): Promise<FinancialModule> {
    const parameters = {'householdId': householdId, 'name': name, 'createEnvelopes': createEnvelopes};
    // @ts-ignore
    return this.api.rpc('financial', 'createFinancialModule', parameters);
  }
  public deleteFinancialModule(object: FinancialModule): Promise<void> {
    const parameters = {'object': object};
    // @ts-ignore
    return this.api.rpc('financial', 'deleteFinancialModule', parameters);
  }
  public generateFinancialModuleEmailHooks(id: string, name: string): Promise<EmailHook> {
    const parameters = {'id': id, 'name': name};
    // @ts-ignore
    return this.api.rpc('financial', 'generateFinancialModuleEmailHooks', parameters);
  }
  public readFinancialModuleEmailHooks(id: string): Promise<EmailHook[]> {
    const parameters = {'id': id};
    // @ts-ignore
    return this.api.rpc('financial', 'readFinancialModuleEmailHooks', parameters);
  }
  public saveFinancialModule(financialModule: FinancialModule): Promise<FinancialModule> {
    const parameters = {'financialModule': financialModule};
    // @ts-ignore
    return this.api.rpc('financial', 'saveFinancialModule', parameters);
  }
  public subscribeToFinancialModule(id: string, handler: (financialmodule: FinancialModule) => void): Subscription {
    return this.api.subscribe('FinancialModule', id, handler);
  }
}

// financial_account
class RPCHandler_financial_account {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public createAccount(uiAccount: UIAccount, initialTransactionDate: any /*java.time.LocalDate*/): Promise<UIAccount> {
    const parameters = {'uiAccount': uiAccount, 'initialTransactionDate': initialTransactionDate};
    // @ts-ignore
    return this.api.rpc('financial_account', 'createAccount', parameters);
  }
  public saveAccount(uiAccount: UIAccount): Promise<UIAccount> {
    const parameters = {'uiAccount': uiAccount};
    // @ts-ignore
    return this.api.rpc('financial_account', 'saveAccount', parameters);
  }
  public subscribeToAccounts(id: string, handler: (uiaccount: UIAccount) => void): Subscription {
    return this.api.subscribe('UIAccount', 'parent:' + id, handler);
  }
  public subscribeToAccountsManageArray(id: string, objects: UIAccount[], addNew: boolean = true): Subscription {
    return this.api.subscribe('UIAccount', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
}

// financial_income_source
class RPCHandler_financial_income_source {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public createIncomeSource(incomeSource: IncomeSource): Promise<IncomeSource> {
    const parameters = {'incomeSource': incomeSource};
    // @ts-ignore
    return this.api.rpc('financial_income_source', 'createIncomeSource', parameters);
  }
  public deleteIncomeSource(incomeSource: IncomeSource): Promise<IncomeSource> {
    const parameters = {'incomeSource': incomeSource};
    // @ts-ignore
    return this.api.rpc('financial_income_source', 'deleteIncomeSource', parameters);
  }
  public saveIncomeSource(incomeSource: IncomeSource): Promise<IncomeSource> {
    const parameters = {'incomeSource': incomeSource};
    // @ts-ignore
    return this.api.rpc('financial_income_source', 'saveIncomeSource', parameters);
  }
  public subscribeToIncomeSources(id: string, handler: (incomesource: IncomeSource) => void): Subscription {
    return this.api.subscribe('IncomeSource', 'parent:' + id, handler);
  }
  public subscribeToIncomeSourcesManageArray(id: string, objects: IncomeSource[], addNew: boolean = true): Subscription {
    return this.api.subscribe('IncomeSource', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
}

// financial_investments
class RPCHandler_financial_investments {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public createAssetClass(financialModuleId: string, name: string): Promise<AssetClass> {
    const parameters = {'financialModuleId': financialModuleId, 'name': name};
    // @ts-ignore
    return this.api.rpc('financial_investments', 'createAssetClass', parameters);
  }
  public createInvestmentPurpose(financialModuleId: string, investmentPurposeName: string): Promise<InvestmentPurpose> {
    const parameters = {'financialModuleId': financialModuleId, 'investmentPurposeName': investmentPurposeName};
    // @ts-ignore
    return this.api.rpc('financial_investments', 'createInvestmentPurpose', parameters);
  }
  public deletePurpose(purpose: InvestmentPurpose): Promise<InvestmentPurpose> {
    const parameters = {'purpose': purpose};
    // @ts-ignore
    return this.api.rpc('financial_investments', 'deletePurpose', parameters);
  }
  public getHoldings(uiPurpose: InvestmentPurpose): Promise<UIHolding[]> {
    const parameters = {'uiPurpose': uiPurpose};
    // @ts-ignore
    return this.api.rpc('financial_investments', 'getHoldings', parameters);
  }
  public getSecuritiesUsedByFinancialModule(financialModuleId: string): Promise<Security[]> {
    const parameters = {'financialModuleId': financialModuleId};
    // @ts-ignore
    return this.api.rpc('financial_investments', 'getSecuritiesUsedByFinancialModule', parameters);
  }
  public getValuesByAccountIds(uiPurpose: InvestmentPurpose): Promise<ChartData> {
    const parameters = {'uiPurpose': uiPurpose};
    // @ts-ignore
    return this.api.rpc('financial_investments', 'getValuesByAccountIds', parameters);
  }
  public saveFinancialModuleSecurity(financialModuleId: string, financialModuleSecurityId: string, version: number, securityId: string, name: string, useDefaultAssetAllocations: boolean, assetAllocations: AssetAllocation[]): Promise<FinancialModuleSecurity> {
    const parameters = {'financialModuleId': financialModuleId, 'financialModuleSecurityId': financialModuleSecurityId, 'version': version, 'securityId': securityId, 'name': name, 'useDefaultAssetAllocations': useDefaultAssetAllocations, 'assetAllocations': assetAllocations};
    // @ts-ignore
    return this.api.rpc('financial_investments', 'saveFinancialModuleSecurity', parameters);
  }
  public savePurpose(purpose: InvestmentPurpose): Promise<InvestmentPurpose> {
    const parameters = {'purpose': purpose};
    // @ts-ignore
    return this.api.rpc('financial_investments', 'savePurpose', parameters);
  }
  public subscribeToFinancialModuleAssetClasses(id: string, handler: (assetclass: AssetClass) => void): Subscription {
    return this.api.subscribe('AssetClass', 'parent:' + id, handler);
  }
  public subscribeToFinancialModuleAssetClassesManageArray(id: string, objects: AssetClass[], addNew: boolean = true): Subscription {
    return this.api.subscribe('AssetClass', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
  public subscribeToFinancialModuleSecurities(id: string, handler: (financialmodulesecurity: FinancialModuleSecurity) => void): Subscription {
    return this.api.subscribe('FinancialModuleSecurity', 'parent:' + id, handler);
  }
  public subscribeToFinancialModuleSecuritiesManageArray(id: string, objects: FinancialModuleSecurity[], addNew: boolean = true): Subscription {
    return this.api.subscribe('FinancialModuleSecurity', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
  public subscribeToGlobalAssetClasses(handler: (assetclass: AssetClass) => void): Subscription {
    return this.api.subscribe('AssetClass', 'all', handler);
  }
  public subscribeToGlobalAssetClassesManageArray(objects: AssetClass[], addNew: boolean = true): Subscription {
    return this.api.subscribe('AssetClass', 'all', this.api.arrayHandler(objects, addNew));
  }
  public subscribeToInvestmentPurposes(id: string, handler: (investmentpurpose: InvestmentPurpose) => void): Subscription {
    return this.api.subscribe('InvestmentPurpose', 'parent:' + id, handler);
  }
  public subscribeToInvestmentPurposesManageArray(id: string, objects: InvestmentPurpose[], addNew: boolean = true): Subscription {
    return this.api.subscribe('InvestmentPurpose', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
  public subscribeToSecurities(handler: (security: Security) => void): Subscription {
    return this.api.subscribe('Security', 'all', handler);
  }
  public subscribeToSecuritiesManageArray(objects: Security[], addNew: boolean = true): Subscription {
    return this.api.subscribe('Security', 'all', this.api.arrayHandler(objects, addNew));
  }
}

// financial_transaction
class RPCHandler_financial_transaction {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public createTransaction(uiTransaction: UITransaction): Promise<UITransaction> {
    const parameters = {'uiTransaction': uiTransaction};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'createTransaction', parameters);
  }
  public deleteEnvelope(envelope: UIEnvelope): Promise<Envelope> {
    const parameters = {'envelope': envelope};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'deleteEnvelope', parameters);
  }
  public deleteUITransaction(transaction: UITransaction): Promise<void> {
    const parameters = {'transaction': transaction};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'deleteUITransaction', parameters);
  }
  public geMonthlySummaryBySourceByEnvelope(financialModuleId: string, envelopeIds: string[], startDate: any /*java.time.LocalDate*/, endDate: any /*java.time.LocalDate*/): Promise<ChartData> {
    const parameters = {'financialModuleId': financialModuleId, 'envelopeIds': envelopeIds, 'startDate': startDate, 'endDate': endDate};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'geMonthlySummaryBySourceByEnvelope', parameters);
  }
  public getDailyValueByEnvelope(financialModuleId: string, envelopeIds: string[], startDate: any /*java.time.LocalDate*/, endDate: any /*java.time.LocalDate*/): Promise<ChartData> {
    const parameters = {'financialModuleId': financialModuleId, 'envelopeIds': envelopeIds, 'startDate': startDate, 'endDate': endDate};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'getDailyValueByEnvelope', parameters);
  }
  public getOrCreateInitialTransaction(account: Account, initialTransactionDate: any /*java.time.LocalDate*/): Promise<CreditDebitTransaction> {
    const parameters = {'account': account, 'initialTransactionDate': initialTransactionDate};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'getOrCreateInitialTransaction', parameters);
  }
  public getTransactions(financialModuleId: string, accountIds: string[], envelopeIds: string[], startDate: any /*java.time.LocalDate*/, endDate: any /*java.time.LocalDate*/, query: string, amount: number, incomeSourceIds: string[], creditDebit: string): Promise<UITransaction[]> {
    const parameters = {'financialModuleId': financialModuleId, 'accountIds': accountIds, 'envelopeIds': envelopeIds, 'startDate': startDate, 'endDate': endDate, 'query': query, 'amount': amount, 'incomeSourceIds': incomeSourceIds, 'creditDebit': creditDebit};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'getTransactions', parameters);
  }
  public saveEnvelope(envelope: UIEnvelope): Promise<Envelope> {
    const parameters = {'envelope': envelope};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'saveEnvelope', parameters);
  }
  public saveEnvelopeTransaction(transaction: EnvelopeTransaction): Promise<EnvelopeTransaction> {
    const parameters = {'transaction': transaction};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'saveEnvelopeTransaction', parameters);
  }
  public saveUITransaction(transaction: UITransaction): Promise<void> {
    const parameters = {'transaction': transaction};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'saveUITransaction', parameters);
  }
  public saveUITransactionWithoutEnvelopes(transaction: UITransaction): Promise<void> {
    const parameters = {'transaction': transaction};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'saveUITransactionWithoutEnvelopes', parameters);
  }
  public transferAmount(financialModuleId: string, date: any /*java.time.LocalDate*/, memo: string, fromEnvelopeId: string, toEnvelopeId: string, amount: number): Promise<void> {
    const parameters = {'financialModuleId': financialModuleId, 'date': date, 'memo': memo, 'fromEnvelopeId': fromEnvelopeId, 'toEnvelopeId': toEnvelopeId, 'amount': amount};
    // @ts-ignore
    return this.api.rpc('financial_transaction', 'transferAmount', parameters);
  }
  public subscribeToEnvelopes(id: string, handler: (uienvelope: UIEnvelope) => void): Subscription {
    return this.api.subscribe('UIEnvelope', 'parent:' + id, handler);
  }
  public subscribeToEnvelopesManageArray(id: string, objects: UIEnvelope[], addNew: boolean = true): Subscription {
    return this.api.subscribe('UIEnvelope', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
  public subscribeToTransactions(id: string, handler: (uitransaction: UITransaction) => void): Subscription {
    return this.api.subscribe('UITransaction', 'parent:' + id, handler);
  }
  public subscribeToTransactionsManageArray(id: string, objects: UITransaction[], addNew: boolean = true): Subscription {
    return this.api.subscribe('UITransaction', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
}

// financial_transaction_import
class RPCHandler_financial_transaction_import {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public deletePendingSourceTransaction(financialModuleId: string, transactionSourceId: string): Promise<void> {
    const parameters = {'financialModuleId': financialModuleId, 'transactionSourceId': transactionSourceId};
    // @ts-ignore
    return this.api.rpc('financial_transaction_import', 'deletePendingSourceTransaction', parameters);
  }
  public previewPendingSourceTransaction(financialModuleId: string, transactionSourceId: string): Promise<TransactionImport> {
    const parameters = {'financialModuleId': financialModuleId, 'transactionSourceId': transactionSourceId};
    // @ts-ignore
    return this.api.rpc('financial_transaction_import', 'previewPendingSourceTransaction', parameters);
  }
  public processPendingSourceTransaction(financialModuleId: string, transactionSourceId: string): Promise<void> {
    const parameters = {'financialModuleId': financialModuleId, 'transactionSourceId': transactionSourceId};
    // @ts-ignore
    return this.api.rpc('financial_transaction_import', 'processPendingSourceTransaction', parameters);
  }
  public setTransactionImportAccountOverride(financialModuleId: string, transactionSourceId: string, accountIdOverride: string): Promise<void> {
    const parameters = {'financialModuleId': financialModuleId, 'transactionSourceId': transactionSourceId, 'accountIdOverride': accountIdOverride};
    // @ts-ignore
    return this.api.rpc('financial_transaction_import', 'setTransactionImportAccountOverride', parameters);
  }
  public uploadImportFile(transactionSource: TransactionImport, content: string): Promise<void> {
    const parameters = {'transactionSource': transactionSource, 'content': content};
    // @ts-ignore
    return this.api.rpc('financial_transaction_import', 'uploadImportFile', parameters);
  }
  public subscribeToRecentSourceTransactions(id: string, handler: (transactionimport: TransactionImport) => void): Subscription {
    return this.api.subscribe('TransactionImport', 'parent:' + id, handler);
  }
  public subscribeToRecentSourceTransactionsManageArray(id: string, objects: TransactionImport[], addNew: boolean = true): Subscription {
    return this.api.subscribe('TransactionImport', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
}

// household
class RPCHandler_household {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public createHousehold(name: string): Promise<Household> {
    const parameters = {'name': name};
    // @ts-ignore
    return this.api.rpc('household', 'createHousehold', parameters);
  }
  public deleteHousehold(id: string): Promise<void> {
    const parameters = {'id': id};
    // @ts-ignore
    return this.api.rpc('household', 'deleteHousehold', parameters);
  }
  public getAllHouseholds(): Promise<Household[]> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('household', 'getAllHouseholds', parameters);
  }
  public getHousehold(id: string): Promise<Household> {
    const parameters = {'id': id};
    // @ts-ignore
    return this.api.rpc('household', 'getHousehold', parameters);
  }
  public saveHousehold(newHousehold: Household): Promise<Household> {
    const parameters = {'newHousehold': newHousehold};
    // @ts-ignore
    return this.api.rpc('household', 'saveHousehold', parameters);
  }
  public saveHouseholdAdmin(newHousehold: Household): Promise<Household> {
    const parameters = {'newHousehold': newHousehold};
    // @ts-ignore
    return this.api.rpc('household', 'saveHouseholdAdmin', parameters);
  }
  public subscribe(id: string, handler: (household: Household) => void): Subscription {
    return this.api.subscribe('Household', id, handler);
  }
  public subscribeAll(handler: (household: Household) => void): Subscription {
    return this.api.subscribe('Household', 'all', handler);
  }
  public subscribeAllManageArray(objects: Household[], addNew: boolean = true): Subscription {
    return this.api.subscribe('Household', 'all', this.api.arrayHandler(objects, addNew));
  }
}

// list
class RPCHandler_list {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public closeListItem(listItem: ListItem): Promise<void> {
    const parameters = {'listItem': listItem};
    // @ts-ignore
    return this.api.rpc('list', 'closeListItem', parameters);
  }
  public createList(listHolderId: string, name: string): Promise<List> {
    const parameters = {'listHolderId': listHolderId, 'name': name};
    // @ts-ignore
    return this.api.rpc('list', 'createList', parameters);
  }
  public createListHolder(householdId: string, name: string): Promise<ListHolder> {
    const parameters = {'householdId': householdId, 'name': name};
    // @ts-ignore
    return this.api.rpc('list', 'createListHolder', parameters);
  }
  public createListItem(listId: string, text: string, position: number): Promise<ListItem> {
    const parameters = {'listId': listId, 'text': text, 'position': position};
    // @ts-ignore
    return this.api.rpc('list', 'createListItem', parameters);
  }
  public deleteList(list: List): Promise<void> {
    const parameters = {'list': list};
    // @ts-ignore
    return this.api.rpc('list', 'deleteList', parameters);
  }
  public deleteListHolder(listHolder: ListHolder): Promise<void> {
    const parameters = {'listHolder': listHolder};
    // @ts-ignore
    return this.api.rpc('list', 'deleteListHolder', parameters);
  }
  public openListItem(listItem: ListItem): Promise<void> {
    const parameters = {'listItem': listItem};
    // @ts-ignore
    return this.api.rpc('list', 'openListItem', parameters);
  }
  public saveList(list: List): Promise<List> {
    const parameters = {'list': list};
    // @ts-ignore
    return this.api.rpc('list', 'saveList', parameters);
  }
  public saveListHolder(listHolder: ListHolder): Promise<ListHolder> {
    const parameters = {'listHolder': listHolder};
    // @ts-ignore
    return this.api.rpc('list', 'saveListHolder', parameters);
  }
  public saveListItem(listItem: ListItem): Promise<void> {
    const parameters = {'listItem': listItem};
    // @ts-ignore
    return this.api.rpc('list', 'saveListItem', parameters);
  }
  public saveOrder(listId: string, items: ListItem[]): Promise<void> {
    const parameters = {'listId': listId, 'items': items};
    // @ts-ignore
    return this.api.rpc('list', 'saveOrder', parameters);
  }
  public sendList(listId: string): Promise<void> {
    const parameters = {'listId': listId};
    // @ts-ignore
    return this.api.rpc('list', 'sendList', parameters);
  }
  public wipeList(listId: string): Promise<void> {
    const parameters = {'listId': listId};
    // @ts-ignore
    return this.api.rpc('list', 'wipeList', parameters);
  }
  public subscribeToList(id: string, handler: (uilist: UIList) => void): Subscription {
    return this.api.subscribe('UIList', id, handler);
  }
  public subscribeToListHolder(id: string, handler: (uilistholder: UIListHolder) => void): Subscription {
    return this.api.subscribe('UIListHolder', id, handler);
  }
}

// module
class RPCHandler_module {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public subscribe(id: string, handler: (uimodule: UIModule) => void): Subscription {
    return this.api.subscribe('UIModule', id, handler);
  }
}

// newton
class RPCHandler_newton {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public connectNewtonToHousehold(householdId: string, code: string): Promise<Newton> {
    const parameters = {'householdId': householdId, 'code': code};
    // @ts-ignore
    return this.api.rpc('newton', 'connectNewtonToHousehold', parameters);
  }
  public getAllNewtons(): Promise<Newton[]> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('newton', 'getAllNewtons', parameters);
  }
  public getNewton(id: string): Promise<Newton> {
    const parameters = {'id': id};
    // @ts-ignore
    return this.api.rpc('newton', 'getNewton', parameters);
  }
  public removeHouseholdFromNewton(id: string): Promise<Newton> {
    const parameters = {'id': id};
    // @ts-ignore
    return this.api.rpc('newton', 'removeHouseholdFromNewton', parameters);
  }
  public saveNewton(newNewton: Newton): Promise<Newton> {
    const parameters = {'newNewton': newNewton};
    // @ts-ignore
    return this.api.rpc('newton', 'saveNewton', parameters);
  }
  public saveNewtonAdmin(newNewton: Newton): Promise<Newton> {
    const parameters = {'newNewton': newNewton};
    // @ts-ignore
    return this.api.rpc('newton', 'saveNewtonAdmin', parameters);
  }
  public subscribe(id: string, handler: (newton: Newton) => void): Subscription {
    return this.api.subscribe('Newton', id, handler);
  }
  public subscribeAll(handler: (newton: Newton) => void): Subscription {
    return this.api.subscribe('Newton', 'all', handler);
  }
  public subscribeAllManageArray(objects: Newton[], addNew: boolean = true): Subscription {
    return this.api.subscribe('Newton', 'all', this.api.arrayHandler(objects, addNew));
  }
}

// notifications
class RPCHandler_notifications {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public addNotificationChannel(uiNotificationChannel: UINotificationChannel): Promise<UINotificationChannel> {
    const parameters = {'uiNotificationChannel': uiNotificationChannel};
    // @ts-ignore
    return this.api.rpc('notifications', 'addNotificationChannel', parameters);
  }
  public createNotificationSubscription(notificationTemplateId: string, moduleId: string, notificationChannelId: string): Promise<NotificationSubscription> {
    const parameters = {'notificationTemplateId': notificationTemplateId, 'moduleId': moduleId, 'notificationChannelId': notificationChannelId};
    // @ts-ignore
    return this.api.rpc('notifications', 'createNotificationSubscription', parameters);
  }
  public deleteNotificationChannel(notificationChannel: UINotificationChannel): Promise<UINotificationChannel> {
    const parameters = {'notificationChannel': notificationChannel};
    // @ts-ignore
    return this.api.rpc('notifications', 'deleteNotificationChannel', parameters);
  }
  public getNotificationTemplatesByModule(): Promise<UINotificationTemplate[]> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('notifications', 'getNotificationTemplatesByModule', parameters);
  }
  public getSafetyNumbers(notificationChannelId: string): Promise<UISafetyNumber[]> {
    const parameters = {'notificationChannelId': notificationChannelId};
    // @ts-ignore
    return this.api.rpc('notifications', 'getSafetyNumbers', parameters);
  }
  public resendVerificationCode(uiNotificationChannel: UINotificationChannel): Promise<void> {
    const parameters = {'uiNotificationChannel': uiNotificationChannel};
    // @ts-ignore
    return this.api.rpc('notifications', 'resendVerificationCode', parameters);
  }
  public sendTestNotification(notificationChannelId: string): Promise<void> {
    const parameters = {'notificationChannelId': notificationChannelId};
    // @ts-ignore
    return this.api.rpc('notifications', 'sendTestNotification', parameters);
  }
  public setNotificationDeleted(uiNotificationSubscription: NotificationSubscription, deleted: boolean): Promise<NotificationSubscription> {
    const parameters = {'uiNotificationSubscription': uiNotificationSubscription, 'deleted': deleted};
    // @ts-ignore
    return this.api.rpc('notifications', 'setNotificationDeleted', parameters);
  }
  public updateNotificationChannel(notificationChannel: UINotificationChannel): Promise<UINotificationChannel> {
    const parameters = {'notificationChannel': notificationChannel};
    // @ts-ignore
    return this.api.rpc('notifications', 'updateNotificationChannel', parameters);
  }
  public verifyNotificationChannel(notificationChannel: UINotificationChannel, verificationCode: string): Promise<UINotificationChannel> {
    const parameters = {'notificationChannel': notificationChannel, 'verificationCode': verificationCode};
    // @ts-ignore
    return this.api.rpc('notifications', 'verifyNotificationChannel', parameters);
  }
  public verifySafetyNumber(notificationChannelId: string, uiSafetyNumber: UISafetyNumber): Promise<boolean> {
    const parameters = {'notificationChannelId': notificationChannelId, 'uiSafetyNumber': uiSafetyNumber};
    // @ts-ignore
    return this.api.rpc('notifications', 'verifySafetyNumber', parameters);
  }
  public subscribeToUserChannels(id: string, handler: (uinotificationchannel: UINotificationChannel) => void): Subscription {
    return this.api.subscribe('UINotificationChannel', 'parent:' + id, handler);
  }
  public subscribeToUserChannelsManageArray(id: string, objects: UINotificationChannel[], addNew: boolean = true): Subscription {
    return this.api.subscribe('UINotificationChannel', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
  public subscribeToUserNotificationSubscriptions(id: string, handler: (notificationsubscription: NotificationSubscription) => void): Subscription {
    return this.api.subscribe('NotificationSubscription', 'parent:' + id, handler);
  }
  public subscribeToUserNotificationSubscriptionsManageArray(id: string, objects: NotificationSubscription[], addNew: boolean = true): Subscription {
    return this.api.subscribe('NotificationSubscription', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
}

// passkey
class RPCHandler_passkey {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public deletePasskey(userPasskey: UserPasskey): Promise<void> {
    const parameters = {'userPasskey': userPasskey};
    // @ts-ignore
    return this.api.rpc('passkey', 'deletePasskey', parameters);
  }
  public finishAssertion(requestId: string, webauthnResponse: string): Promise<string> {
    const parameters = {'requestId': requestId, 'webauthnResponse': webauthnResponse};
    // @ts-ignore
    return this.api.rpc('passkey', 'finishAssertion', parameters);
  }
  public finishRegistration(requestId: string, webauthnResponse: string): Promise<void> {
    const parameters = {'requestId': requestId, 'webauthnResponse': webauthnResponse};
    // @ts-ignore
    return this.api.rpc('passkey', 'finishRegistration', parameters);
  }
  public startAssertion(): Promise<WebAuthnRequest> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('passkey', 'startAssertion', parameters);
  }
  public startRegistration(passkeyName: string): Promise<WebAuthnRequest> {
    const parameters = {'passkeyName': passkeyName};
    // @ts-ignore
    return this.api.rpc('passkey', 'startRegistration', parameters);
  }
  public subscribeToUserPasskeys(id: string, handler: (userpasskey: UserPasskey) => void): Subscription {
    return this.api.subscribe('UserPasskey', 'parent:' + id, handler);
  }
  public subscribeToUserPasskeysManageArray(id: string, objects: UserPasskey[], addNew: boolean = true): Subscription {
    return this.api.subscribe('UserPasskey', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
}

// permission
class RPCHandler_permission {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public changePermissionLevel(authorityId: string, newPermissionLevel: PermissionLevel): Promise<void> {
    const parameters = {'authorityId': authorityId, 'newPermissionLevel': newPermissionLevel};
    // @ts-ignore
    return this.api.rpc('permission', 'changePermissionLevel', parameters);
  }
  public revokeAuthority(authorityId: string): Promise<void> {
    const parameters = {'authorityId': authorityId};
    // @ts-ignore
    return this.api.rpc('permission', 'revokeAuthority', parameters);
  }
  public subscribe(id: string, handler: (uipermissions: UIPermissions) => void): Subscription {
    return this.api.subscribe('UIPermissions', id, handler);
  }
}

// petal
class RPCHandler_petal {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public invokePetalMethod(id: string, methodName: string, booleanParameter: boolean, longParameter: number, floatParameter: any /*java.lang.Float*/, stringParameter: string): Promise<void> {
    const parameters = {'id': id, 'methodName': methodName, 'booleanParameter': booleanParameter, 'longParameter': longParameter, 'floatParameter': floatParameter, 'stringParameter': stringParameter};
    // @ts-ignore
    return this.api.rpc('petal', 'invokePetalMethod', parameters);
  }
  public markPetalAsConnected(petalWebsocketConnectionDetails: PetalWebsocketConnectionDetails): Promise<void> {
    const parameters = {'petalWebsocketConnectionDetails': petalWebsocketConnectionDetails};
    // @ts-ignore
    return this.api.rpc('petal', 'markPetalAsConnected', parameters);
  }
  public markPetalAsConnectedForProvision(macAddress: string, firmwareName: string, firmwareCommit: string): Promise<PetalConfig> {
    const parameters = {'macAddress': macAddress, 'firmwareName': firmwareName, 'firmwareCommit': firmwareCommit};
    // @ts-ignore
    return this.api.rpc('petal', 'markPetalAsConnectedForProvision', parameters);
  }
  public markPetalAsDisconnected(macAddress: string): Promise<void> {
    const parameters = {'macAddress': macAddress};
    // @ts-ignore
    return this.api.rpc('petal', 'markPetalAsDisconnected', parameters);
  }
  public markPetalAsProvisioned(macAddress: string): Promise<void> {
    const parameters = {'macAddress': macAddress};
    // @ts-ignore
    return this.api.rpc('petal', 'markPetalAsProvisioned', parameters);
  }
  public markPetalAsUpdating(petalWebsocketConnectionDetails: PetalWebsocketConnectionDetails): Promise<void> {
    const parameters = {'petalWebsocketConnectionDetails': petalWebsocketConnectionDetails};
    // @ts-ignore
    return this.api.rpc('petal', 'markPetalAsUpdating', parameters);
  }
  public savePetal(petal: Petal): Promise<Petal> {
    const parameters = {'petal': petal};
    // @ts-ignore
    return this.api.rpc('petal', 'savePetal', parameters);
  }
  public subscribeByParent(id: string, handler: (petal: Petal) => void): Subscription {
    return this.api.subscribe('Petal', 'parent:' + id, handler);
  }
  public subscribeByParentManageArray(id: string, objects: Petal[], addNew: boolean = true): Subscription {
    return this.api.subscribe('Petal', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
  public subscribeToCommandsByNewtonId(id: string, handler: (petalcommand: PetalCommand) => void): Subscription {
    return this.api.subscribe('PetalCommand', 'parent:' + id, handler);
  }
  public subscribeToCommandsByNewtonIdManageArray(id: string, objects: PetalCommand[], addNew: boolean = true): Subscription {
    return this.api.subscribe('PetalCommand', 'parent:' + id, this.api.arrayHandler(objects, addNew));
  }
}

// user
class RPCHandler_user {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public changePassword(id: string, version: number, currentPassword: string, newPassword: string): Promise<void> {
    const parameters = {'id': id, 'version': version, 'currentPassword': currentPassword, 'newPassword': newPassword};
    // @ts-ignore
    return this.api.rpc('user', 'changePassword', parameters);
  }
  public getAllUsers(): Promise<User[]> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('user', 'getAllUsers', parameters);
  }
  public getFailedLogins(): Promise<LoginHistoryEvent[]> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('user', 'getFailedLogins', parameters);
  }
  public getSuccessfulLogins(): Promise<LoginHistoryEvent[]> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('user', 'getSuccessfulLogins', parameters);
  }
  public isLoggedIn(): Promise<boolean> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('user', 'isLoggedIn', parameters);
  }
  public registerUser(name: string, emailAddress: string /*EmailAddress*/, username: string, password: string, userEnteredSecurityCode: string): Promise<User> {
    const parameters = {'name': name, 'emailAddress': emailAddress, 'username': username, 'password': password, 'userEnteredSecurityCode': userEnteredSecurityCode};
    // @ts-ignore
    return this.api.rpc('user', 'registerUser', parameters);
  }
  public saveUserAdmin(newUser: User): Promise<User> {
    const parameters = {'newUser': newUser};
    // @ts-ignore
    return this.api.rpc('user', 'saveUserAdmin', parameters);
  }
  public saveUserEmail(id: string, version: number, emailAddress: string /*EmailAddress*/, userEnteredSecurityCode: string): Promise<void> {
    const parameters = {'id': id, 'version': version, 'emailAddress': emailAddress, 'userEnteredSecurityCode': userEnteredSecurityCode};
    // @ts-ignore
    return this.api.rpc('user', 'saveUserEmail', parameters);
  }
  public saveUserProfile(newUser: User): Promise<void> {
    const parameters = {'newUser': newUser};
    // @ts-ignore
    return this.api.rpc('user', 'saveUserProfile', parameters);
  }
  public sendVerificationEmail(emailAddress: string /*EmailAddress*/): Promise<string /*EmailAddress*/> {
    const parameters = {'emailAddress': emailAddress};
    // @ts-ignore
    return this.api.rpc('user', 'sendVerificationEmail', parameters);
  }
  public subscribe(id: string, handler: (uiuserfull: UIUserFull) => void): Subscription {
    return this.api.subscribe('UIUserFull', id, handler);
  }
  public subscribeAll(handler: (uiuserfull: UIUserFull) => void): Subscription {
    return this.api.subscribe('UIUserFull', 'all', handler);
  }
  public subscribeAllManageArray(objects: UIUserFull[], addNew: boolean = true): Subscription {
    return this.api.subscribe('UIUserFull', 'all', this.api.arrayHandler(objects, addNew));
  }
}

// webrepo
class RPCHandler_webrepo {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public getAllCurrentArtifacts(): Promise<Artifact[]> {
    const parameters = {};
    // @ts-ignore
    return this.api.rpc('webrepo', 'getAllCurrentArtifacts', parameters);
  }
  public subscribe(id: string, handler: (artifact: Artifact) => void): Subscription {
    return this.api.subscribe('Artifact', id, handler);
  }
  public subscribeAll(handler: (artifact: Artifact) => void): Subscription {
    return this.api.subscribe('Artifact', 'all', handler);
  }
  public subscribeAllManageArray(objects: Artifact[], addNew: boolean = true): Subscription {
    return this.api.subscribe('Artifact', 'all', this.api.arrayHandler(objects, addNew));
  }
}

// co.parry.hoa.common.ai.AIMemory
export class AIMemory {
  id: string;
  version: number;
  aiPrincipalId: string;
  type: AIMemoryType;
  memoryText: string;
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, aiPrincipalId: string, type: AIMemoryType, memoryText: string, createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.aiPrincipalId = aiPrincipalId;
    this.type = type;
    this.memoryText = memoryText;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.common.ai.AIMemory$AIMemoryType
export enum AIMemoryType {
  USER = "USER",
  ASSISTANT = "ASSISTANT",
  SYSTEM = "SYSTEM",
  TOOL = "TOOL",
  MEMORY = "MEMORY",
}

// co.parry.hoa.common.ai.AIModel
export enum AIModel {
  SMALL = "SMALL",
  LARGE = "LARGE",
}

// co.parry.hoa.common.ai.AIPrincipal
export class AIPrincipal {
  id: string;
  version: number;
  name: string;
  aiModel: AIModel;
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, name: string, aiModel: AIModel, createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.name = name;
    this.aiModel = aiModel;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.common.calendar.Calendar
export class Calendar {
  id: string;
  version: number;
  calendarHolderId: string;
  name: string;
  description: string;
  color: string;
  url: string;
  lastSync: string /*Instant*/;
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, calendarHolderId: string, name: string, description: string, color: string, url: string, lastSync: string /*Instant*/, createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.calendarHolderId = calendarHolderId;
    this.name = name;
    this.description = description;
    this.color = color;
    this.url = url;
    this.lastSync = lastSync;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.common.calendar.CalendarEvent
export class CalendarEvent {
  id: string;
  version: number;
  calendarId: string;
  name: string;
  description: string;
  location: string;
  start: string /*Instant*/;
  end: string /*Instant*/;
  fullDayStartDate: any /*java.time.LocalDate*/;
  fullDayEndDate: any /*java.time.LocalDate*/;
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, calendarId: string, name: string, description: string, location: string, start: string /*Instant*/, end: string /*Instant*/, fullDayStartDate: any /*java.time.LocalDate*/, fullDayEndDate: any /*java.time.LocalDate*/, createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.calendarId = calendarId;
    this.name = name;
    this.description = description;
    this.location = location;
    this.start = start;
    this.end = end;
    this.fullDayStartDate = fullDayStartDate;
    this.fullDayEndDate = fullDayEndDate;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.common.calendar.CalendarHolder
export class CalendarHolder {
  id: string;
  version: number;
  householdId: string;
  name: string;
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, householdId: string, name: string, createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.householdId = householdId;
    this.name = name;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.common.integration.CloudIntegration
export class CloudIntegration {
  id: string;
  version: number;
  householdId: string;
  name: string;
  type: CloudIntegrationType;
  username: string;
  password: string;
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, householdId: string, name: string, type: CloudIntegrationType, username: string, password: string, createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.householdId = householdId;
    this.name = name;
    this.type = type;
    this.username = username;
    this.password = password;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.common.integration.CloudIntegrationType
export enum CloudIntegrationType {
  YOLINK = "YoLink",
}

// co.parry.hoa.common.newton.Newton
export class Newton {
  id: string;
  version: number;
  name: string;
  householdId: string;
  enabled: boolean;
  codeHash: string;
  verificationCode: string;
  status: NewtonStatus;
  currentHostname: string;
  statusTimestamp: string /*Instant*/;
  remoteIP: string;
  localIP: string;
  petalSSID: string;
  petalPassphrase: string;

  constructor(id: string, version: number, name: string, householdId: string, enabled: boolean, codeHash: string, verificationCode: string, status: NewtonStatus, currentHostname: string, statusTimestamp: string /*Instant*/, remoteIP: string, localIP: string, petalSSID: string, petalPassphrase: string) {
    this.id = id;
    this.version = version;
    this.name = name;
    this.householdId = householdId;
    this.enabled = enabled;
    this.codeHash = codeHash;
    this.verificationCode = verificationCode;
    this.status = status;
    this.currentHostname = currentHostname;
    this.statusTimestamp = statusTimestamp;
    this.remoteIP = remoteIP;
    this.localIP = localIP;
    this.petalSSID = petalSSID;
    this.petalPassphrase = petalPassphrase;
  }
}

// co.parry.hoa.common.newton.NewtonStatus
export enum NewtonStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  UPDATING = "UPDATING",
}

// co.parry.hoa.common.notification.NotificationChannel$NotificationChannelType
export enum NotificationChannelType {
  EMAIL = "Email",
  SIGNAL = "Signal",
  SMS_EMAIL = "SMS Over Email",
  PWA_PUSH = "Web Push",
}

// co.parry.hoa.common.notification.NotificationSubscription
export class NotificationSubscription {
  id: string;
  version: number;
  userId: string;
  notificationTemplateId: string;
  moduleId: string;
  notificationChannelId: string;
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, userId: string, notificationTemplateId: string, moduleId: string, notificationChannelId: string, createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.userId = userId;
    this.notificationTemplateId = notificationTemplateId;
    this.moduleId = moduleId;
    this.notificationChannelId = notificationChannelId;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.common.notification.UINotificationChannel
export class UINotificationChannel {
  id: string;
  version: number;
  userId: string;
  type: NotificationChannelType;
  name: string;
  address: string;
  aiPrincipalId: string;
  verified: boolean;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, userId: string, type: NotificationChannelType, name: string, address: string, aiPrincipalId: string, verified: boolean, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.userId = userId;
    this.type = type;
    this.name = name;
    this.address = address;
    this.aiPrincipalId = aiPrincipalId;
    this.verified = verified;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.common.notification.UINotificationTemplate
export class UINotificationTemplate {
  moduleClass: any /*java.lang.Class*/;
  moduleClassId: string;
  moduleClassname: string;
  notificationTemplateId: string;
  name: string;
  description: string;

  constructor(moduleClass: any /*java.lang.Class*/, moduleClassId: string, moduleClassname: string, notificationTemplateId: string, name: string, description: string) {
    this.moduleClass = moduleClass;
    this.moduleClassId = moduleClassId;
    this.moduleClassname = moduleClassname;
    this.notificationTemplateId = notificationTemplateId;
    this.name = name;
    this.description = description;
  }
}

// co.parry.hoa.common.notification.UISafetyNumber
export class UISafetyNumber {
  groupMember: string;
  safetyNumber: string;
  scannableSafetyNumber: string;
  verified: boolean;

  constructor(groupMember: string, safetyNumber: string, scannableSafetyNumber: string, verified: boolean) {
    this.groupMember = groupMember;
    this.safetyNumber = safetyNumber;
    this.scannableSafetyNumber = scannableSafetyNumber;
    this.verified = verified;
  }
}

// co.parry.hoa.common.permission.PermissionLevel
export enum PermissionLevel {
  OWNER = "OWNER",
  WRITE = "WRITE",
  READ = "READ",
}

// co.parry.hoa.common.permission.UIAuthority
export class UIAuthority {
  id: string;
  isInherited: boolean;
  permissionLevel: PermissionLevel;
  user: UIUser;
  grantedByUser: UIUser;
  grantedTimestamp: string /*Instant*/;

  constructor(id: string, isInherited: boolean, permissionLevel: PermissionLevel, user: UIUser, grantedByUser: UIUser, grantedTimestamp: string /*Instant*/) {
    this.id = id;
    this.isInherited = isInherited;
    this.permissionLevel = permissionLevel;
    this.user = user;
    this.grantedByUser = grantedByUser;
    this.grantedTimestamp = grantedTimestamp;
  }
}

// co.parry.hoa.common.permission.UIPermissions
export class UIPermissions {
  id: string;
  type: any /*java.lang.Class*/;
  moduleId: string;
  authorities: UIAuthority[];

  constructor(id: string, type: any /*java.lang.Class*/, moduleId: string, authorities: UIAuthority[]) {
    this.id = id;
    this.type = type;
    this.moduleId = moduleId;
    this.authorities = authorities;
  }
}

// co.parry.hoa.common.petal.Petal
export class Petal {
  id: string;
  version: number;
  newtonId: string;
  macAddress: string;
  token: string;
  authorized: boolean;
  autoUpdate: boolean;
  releaseChannel: ReleaseChannel;
  firmwareName: string;
  firmwareCommit: string;
  usbConnectedTimestamp: string /*Instant*/;
  provisionedTimestamp: string /*Instant*/;
  wsConnectedTimestamp: string /*Instant*/;
  methods: PetalMethod[];
  localIP: string;
  lastResetReason: string;
  connected: boolean;
  updating: boolean;

  constructor(id: string, version: number, newtonId: string, macAddress: string, token: string, authorized: boolean, autoUpdate: boolean, releaseChannel: ReleaseChannel, firmwareName: string, firmwareCommit: string, usbConnectedTimestamp: string /*Instant*/, provisionedTimestamp: string /*Instant*/, wsConnectedTimestamp: string /*Instant*/, methods: PetalMethod[], localIP: string, lastResetReason: string, connected: boolean, updating: boolean) {
    this.id = id;
    this.version = version;
    this.newtonId = newtonId;
    this.macAddress = macAddress;
    this.token = token;
    this.authorized = authorized;
    this.autoUpdate = autoUpdate;
    this.releaseChannel = releaseChannel;
    this.firmwareName = firmwareName;
    this.firmwareCommit = firmwareCommit;
    this.usbConnectedTimestamp = usbConnectedTimestamp;
    this.provisionedTimestamp = provisionedTimestamp;
    this.wsConnectedTimestamp = wsConnectedTimestamp;
    this.methods = methods;
    this.localIP = localIP;
    this.lastResetReason = lastResetReason;
    this.connected = connected;
    this.updating = updating;
  }
}

// co.parry.hoa.common.petal.PetalCommand
export class PetalCommand {
  newtonId: string;
  petalId: string;
  methodName: string;
  booleanParameter: boolean;
  longParameter: number;
  floatParameter: any /*java.lang.Float*/;
  stringParameter: string;

  constructor(newtonId: string, petalId: string, methodName: string, booleanParameter: boolean, longParameter: number, floatParameter: any /*java.lang.Float*/, stringParameter: string) {
    this.newtonId = newtonId;
    this.petalId = petalId;
    this.methodName = methodName;
    this.booleanParameter = booleanParameter;
    this.longParameter = longParameter;
    this.floatParameter = floatParameter;
    this.stringParameter = stringParameter;
  }
}

// co.parry.hoa.common.petal.PetalConfig
export class PetalConfig {
  macAddress: string;
  authorized: boolean;
  wifiSSID: string;
  wifiPassphrase: string;
  newton: string;
  newtonSecret: string;
  newtonCert: string;

  constructor(macAddress: string, authorized: boolean, wifiSSID: string, wifiPassphrase: string, newton: string, newtonSecret: string, newtonCert: string) {
    this.macAddress = macAddress;
    this.authorized = authorized;
    this.wifiSSID = wifiSSID;
    this.wifiPassphrase = wifiPassphrase;
    this.newton = newton;
    this.newtonSecret = newtonSecret;
    this.newtonCert = newtonCert;
  }
}

// co.parry.hoa.common.petal.PetalMethod
export class PetalMethod {
  methodName: string;
  parameterType: ParameterType;

  constructor(methodName: string, parameterType: ParameterType) {
    this.methodName = methodName;
    this.parameterType = parameterType;
  }
}

// co.parry.hoa.common.petal.PetalMethod$ParameterType
export enum ParameterType {
  VOID = "VOID",
  BOOLEAN = "BOOLEAN",
  LONG = "LONG",
  FLOAT = "FLOAT",
  STRING = "STRING",
}

// co.parry.hoa.common.petal.PetalWebsocketConnectionDetails
export class PetalWebsocketConnectionDetails {
  localIp: string;
  macAddress: string;
  firmwareName: string;
  firmwareCommit: string;
  resetReason: string;
  methods: PetalMethod[];

  constructor(localIp: string, macAddress: string, firmwareName: string, firmwareCommit: string, resetReason: string, methods: PetalMethod[]) {
    this.localIp = localIp;
    this.macAddress = macAddress;
    this.firmwareName = firmwareName;
    this.firmwareCommit = firmwareCommit;
    this.resetReason = resetReason;
    this.methods = methods;
  }
}

// co.parry.hoa.common.repository.Artifact
export class Artifact {
  id: string;
  version: number;
  name: string;
  releaseChannel: ReleaseChannel;
  commit: string;
  createdTimestamp: string /*Instant*/;
  hash: MD5;
  fileId: string /*FileId*/;

  constructor(id: string, version: number, name: string, releaseChannel: ReleaseChannel, commit: string, createdTimestamp: string /*Instant*/, hash: MD5, fileId: string /*FileId*/) {
    this.id = id;
    this.version = version;
    this.name = name;
    this.releaseChannel = releaseChannel;
    this.commit = commit;
    this.createdTimestamp = createdTimestamp;
    this.hash = hash;
    this.fileId = fileId;
  }
}

// co.parry.hoa.common.repository.ReleaseChannel
export enum ReleaseChannel {
  STABLE = "STABLE",
  BETA = "BETA",
  DEV = "DEV",
}

// co.parry.hoa.common.rpc.RPCResponse
export class RPCResponse {
  success: boolean;
  errorMessage: string;
  response: any /*java.lang.Object*/;

  constructor(success: boolean, errorMessage: string, response: any /*java.lang.Object*/) {
    this.success = success;
    this.errorMessage = errorMessage;
    this.response = response;
  }
}

// co.parry.hoa.common.rpc.ws.ClientMessage
export class ClientMessage {
  request: WSRequest;
  subscribe: WSSubscribe;
  unsubscribe: WSUnsubscribe;

  constructor(request: WSRequest, subscribe: WSSubscribe, unsubscribe: WSUnsubscribe) {
    this.request = request;
    this.subscribe = subscribe;
    this.unsubscribe = unsubscribe;
  }
}

// co.parry.hoa.common.rpc.ws.ServerMessage
export class ServerMessage {
  publish: WSPublish;
  response: WSResponse;
  unsubscribed: WSUnsubscribed;

  constructor(publish: WSPublish, response: WSResponse, unsubscribed: WSUnsubscribed) {
    this.publish = publish;
    this.response = response;
    this.unsubscribed = unsubscribed;
  }
}

// co.parry.hoa.common.rpc.ws.WSPublish
export class WSPublish {
  subscriptionId: number;
  data: any /*java.lang.Object*/;

  constructor(subscriptionId: number, data: any /*java.lang.Object*/) {
    this.subscriptionId = subscriptionId;
    this.data = data;
  }
}

// co.parry.hoa.common.rpc.ws.WSRequest
export class WSRequest {
  requestId: number;
  appName: string;
  methodName: string;
  args: any /*java.util.Map*/;

  constructor(requestId: number, appName: string, methodName: string, args: any /*java.util.Map*/) {
    this.requestId = requestId;
    this.appName = appName;
    this.methodName = methodName;
    this.args = args;
  }
}

// co.parry.hoa.common.rpc.ws.WSResponse
export class WSResponse {
  requestId: number;
  success: boolean;
  data: any /*java.lang.Object*/;
  errorMessage: string;

  constructor(requestId: number, success: boolean, data: any /*java.lang.Object*/, errorMessage: string) {
    this.requestId = requestId;
    this.success = success;
    this.data = data;
    this.errorMessage = errorMessage;
  }
}

// co.parry.hoa.common.rpc.ws.WSSubscribe
export class WSSubscribe {
  subscriptionId: number;
  className: string;
  id: string;

  constructor(subscriptionId: number, className: string, id: string) {
    this.subscriptionId = subscriptionId;
    this.className = className;
    this.id = id;
  }
}

// co.parry.hoa.common.rpc.ws.WSUnsubscribe
export class WSUnsubscribe {
  subscriptionId: number;

  constructor(subscriptionId: number) {
    this.subscriptionId = subscriptionId;
  }
}

// co.parry.hoa.common.rpc.ws.WSUnsubscribed
export class WSUnsubscribed {
  subscriptionId: number;
  reason: string;

  constructor(subscriptionId: number, reason: string) {
    this.subscriptionId = subscriptionId;
    this.reason = reason;
  }
}

// co.parry.hoa.common.user.LoginHistoryEvent
export class LoginHistoryEvent {
  timestamp: string /*Instant*/;
  remoteIP: string;

  constructor(timestamp: string /*Instant*/, remoteIP: string) {
    this.timestamp = timestamp;
    this.remoteIP = remoteIP;
  }
}

// co.parry.hoa.common.user.UIUser
export class UIUser {
  id: string;
  name: string;
  emailAddress: string /*EmailAddress*/;
  enabled: boolean;

  constructor(id: string, name: string, emailAddress: string /*EmailAddress*/, enabled: boolean) {
    this.id = id;
    this.name = name;
    this.emailAddress = emailAddress;
    this.enabled = enabled;
  }
}

// co.parry.hoa.common.user.UIUserFull
export class UIUserFull {
  id: string;
  version: number;
  verifiedEmailAddress: string /*EmailAddress*/;
  name: string;
  username: string;
  passwordLastChanged: string /*Instant*/;
  config: UserConfig;

  constructor(id: string, version: number, verifiedEmailAddress: string /*EmailAddress*/, name: string, username: string, passwordLastChanged: string /*Instant*/, config: UserConfig) {
    this.id = id;
    this.version = version;
    this.verifiedEmailAddress = verifiedEmailAddress;
    this.name = name;
    this.username = username;
    this.passwordLastChanged = passwordLastChanged;
    this.config = config;
  }
}

// co.parry.hoa.common.user.User
export class User {
  id: string;
  version: number;
  verifiedEmailAddress: string /*EmailAddress*/;
  name: string;
  username: string;
  passwordLastChanged: string /*Instant*/;
  enabled: boolean;

  constructor(id: string, version: number, verifiedEmailAddress: string /*EmailAddress*/, name: string, username: string, passwordLastChanged: string /*Instant*/, enabled: boolean) {
    this.id = id;
    this.version = version;
    this.verifiedEmailAddress = verifiedEmailAddress;
    this.name = name;
    this.username = username;
    this.passwordLastChanged = passwordLastChanged;
    this.enabled = enabled;
  }
}

// co.parry.hoa.common.user.UserConfig
export class UserConfig {
  applicationServerKey: string;

  constructor(applicationServerKey: string) {
    this.applicationServerKey = applicationServerKey;
  }
}

// co.parry.hoa.common.user.UserPasskey
export class UserPasskey {
  id: string;
  version: number;
  userId: string;
  displayName: string;
  useCount: number;
  lastUsedTimestamp: string /*Instant*/;
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, userId: string, displayName: string, useCount: number, lastUsedTimestamp: string /*Instant*/, createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.userId = userId;
    this.displayName = displayName;
    this.useCount = useCount;
    this.lastUsedTimestamp = lastUsedTimestamp;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.common.user.WebAuthnRequest
export class WebAuthnRequest {
  id: string;
  webauthnRequest: string;

  constructor(id: string, webauthnRequest: string) {
    this.id = id;
    this.webauthnRequest = webauthnRequest;
  }
}

// co.parry.hoa.common.util.MD5
export class MD5 {
  md: any /*java.security.MessageDigest*/;
  digest: any /*byte*/[];

  constructor(md: any /*java.security.MessageDigest*/, digest: any /*byte*/[]) {
    this.md = md;
    this.digest = digest;
  }
}

// co.parry.hoa.lux.actionitem.UIActionItem
export class UIActionItem {
  id: string;
  moduleId: string;
  path: string;
  name: string;
  deleted: boolean;

  constructor(id: string, moduleId: string, path: string, name: string, deleted: boolean) {
    this.id = id;
    this.moduleId = moduleId;
    this.path = path;
    this.name = name;
    this.deleted = deleted;
  }
}

// co.parry.hoa.lux.asset.AssetType
export enum AssetType {
  SECURITY = "Security",
  FINANCIAL_MODULE_SECURITY = "Security",
  PROPERTY = "Property",
  VEHICLE = "Vehicle",
}

// co.parry.hoa.lux.email.EmailHook
export class EmailHook {
  id: string;
  version: number;
  moduleClass: any /*java.lang.Class*/;
  moduleId: string;
  name: string;
  emailAddress: string;
  allowedFrom: string[];
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, moduleClass: any /*java.lang.Class*/, moduleId: string, name: string, emailAddress: string, allowedFrom: string[], createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.moduleClass = moduleClass;
    this.moduleId = moduleId;
    this.name = name;
    this.emailAddress = emailAddress;
    this.allowedFrom = allowedFrom;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.Account
export class Account {
  id: string;
  version: number;
  financialModuleId: string;
  name: string;
  type: AccountType;
  emailHookId: string;
  accountNumber: string;
  nameForSync: string;
  forEnvelopes: boolean;
  investmentPurposeId: string;
  accountInvestmentType: AccountInvestmentType;
  creditLimit: number;
  lastFullSync: string /*Instant*/;
  defaultIncomeSourceId: string;
  createdByUserId: string;
  createdTimestamp: string /*Instant*/;
  deletedByUserId: string;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, name: string, type: AccountType, emailHookId: string, accountNumber: string, nameForSync: string, forEnvelopes: boolean, investmentPurposeId: string, accountInvestmentType: AccountInvestmentType, creditLimit: number, lastFullSync: string /*Instant*/, defaultIncomeSourceId: string, createdByUserId: string, createdTimestamp: string /*Instant*/, deletedByUserId: string, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.name = name;
    this.type = type;
    this.emailHookId = emailHookId;
    this.accountNumber = accountNumber;
    this.nameForSync = nameForSync;
    this.forEnvelopes = forEnvelopes;
    this.investmentPurposeId = investmentPurposeId;
    this.accountInvestmentType = accountInvestmentType;
    this.creditLimit = creditLimit;
    this.lastFullSync = lastFullSync;
    this.defaultIncomeSourceId = defaultIncomeSourceId;
    this.createdByUserId = createdByUserId;
    this.createdTimestamp = createdTimestamp;
    this.deletedByUserId = deletedByUserId;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.Account$AccountInvestmentType
export enum AccountInvestmentType {
  PROPERTY = "Property",
  TAXABLE = "Taxable",
  ROTH_RETIREMENT = "Roth Retirement (Roth IRA, Roth 401k, etc.)",
  TRADITIONAL = "Retirement (IRA, 401k, etc.)",
  HSA = "Health Savings Account",
  EDUCATION = "529 Plan",
}

// co.parry.hoa.lux.financial.Account$AccountType
export enum AccountType {
  TRANSFER = "Transfer",
  CASH = "Cash",
  CHECKING = "Checking",
  CREDIT = "Credit",
  INVESTMENT = "Investment",
}

// co.parry.hoa.lux.financial.CreditDebitTransaction
export class CreditDebitTransaction {
  id: string;
  version: number;
  financialModuleId: string;
  accountId: string;
  date: any /*java.time.LocalDate*/;
  amount: number;
  description: string;
  pending: boolean;
  memo: string;
  sourceId: string;
  incomeSourceId: string;
  createdTimestamp: string /*Instant*/;
  updatedTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, accountId: string, date: any /*java.time.LocalDate*/, amount: number, description: string, pending: boolean, memo: string, sourceId: string, incomeSourceId: string, createdTimestamp: string /*Instant*/, updatedTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.accountId = accountId;
    this.date = date;
    this.amount = amount;
    this.description = description;
    this.pending = pending;
    this.memo = memo;
    this.sourceId = sourceId;
    this.incomeSourceId = incomeSourceId;
    this.createdTimestamp = createdTimestamp;
    this.updatedTimestamp = updatedTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.Envelope
export class Envelope {
  id: string;
  version: number;
  financialModuleId: string;
  name: string;
  targetInterval: TargetInterval;
  targetAmount: number;
  maxAmountPerPaycheck: number;
  earmarks: Earmark[];
  hideWhenZero: boolean;
  position: number;
  userEditable: boolean;
  description: string;
  createdTimestamp: string /*Instant*/;
  deletedByUserId: string;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, name: string, targetInterval: TargetInterval, targetAmount: number, maxAmountPerPaycheck: number, earmarks: Earmark[], hideWhenZero: boolean, position: number, userEditable: boolean, description: string, createdTimestamp: string /*Instant*/, deletedByUserId: string, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.name = name;
    this.targetInterval = targetInterval;
    this.targetAmount = targetAmount;
    this.maxAmountPerPaycheck = maxAmountPerPaycheck;
    this.earmarks = earmarks;
    this.hideWhenZero = hideWhenZero;
    this.position = position;
    this.userEditable = userEditable;
    this.description = description;
    this.createdTimestamp = createdTimestamp;
    this.deletedByUserId = deletedByUserId;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.Envelope$Earmark
export class Earmark {
  name: string;
  amount: number;

  constructor(name: string, amount: number) {
    this.name = name;
    this.amount = amount;
  }
}

// co.parry.hoa.lux.financial.Envelope$TargetInterval
export enum TargetInterval {
  NONE = "None",
  PAYCHECK = "Paycheck",
  MONTHLY = "Monthly",
  ANNUAL = "Annual",
  TOP_UP = "Top-up",
  REMAINING_BALANCE = "Remaining balance",
}

// co.parry.hoa.lux.financial.EnvelopeTransaction
export class EnvelopeTransaction {
  id: string;
  version: number;
  financialModuleId: string;
  creditDebitTransactionId: string;
  position: number;
  amount: number;
  memo: string;
  envelopeId: string;
  categoryId: string;
  suggestedEnvelopeIds: string[];
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, creditDebitTransactionId: string, position: number, amount: number, memo: string, envelopeId: string, categoryId: string, suggestedEnvelopeIds: string[], createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.creditDebitTransactionId = creditDebitTransactionId;
    this.position = position;
    this.amount = amount;
    this.memo = memo;
    this.envelopeId = envelopeId;
    this.categoryId = categoryId;
    this.suggestedEnvelopeIds = suggestedEnvelopeIds;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.FinancialModule
export class FinancialModule {
  id: string;
  version: number;
  householdId: string;
  name: string;
  preEnvelopesEnvelopeId: string;
  createdByUserId: string;
  createdTimestamp: string /*Instant*/;
  deletedByUserId: string;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, householdId: string, name: string, preEnvelopesEnvelopeId: string, createdByUserId: string, createdTimestamp: string /*Instant*/, deletedByUserId: string, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.householdId = householdId;
    this.name = name;
    this.preEnvelopesEnvelopeId = preEnvelopesEnvelopeId;
    this.createdByUserId = createdByUserId;
    this.createdTimestamp = createdTimestamp;
    this.deletedByUserId = deletedByUserId;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.IncomeSource
export class IncomeSource {
  id: string;
  version: number;
  financialModuleId: string;
  name: string;
  keywordsCSV: string;
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, name: string, keywordsCSV: string, createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.name = name;
    this.keywordsCSV = keywordsCSV;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.SourceCreditDebitTransaction
export class SourceCreditDebitTransaction {
  accountInfo: string;
  date: any /*java.time.LocalDate*/;
  amount: number;
  description: string;
  pending: boolean;
  memo: string;
  existingCreditDebitTransaction: CreditDebitTransaction;

  constructor(accountInfo: string, date: any /*java.time.LocalDate*/, amount: number, description: string, pending: boolean, memo: string, existingCreditDebitTransaction: CreditDebitTransaction) {
    this.accountInfo = accountInfo;
    this.date = date;
    this.amount = amount;
    this.description = description;
    this.pending = pending;
    this.memo = memo;
    this.existingCreditDebitTransaction = existingCreditDebitTransaction;
  }
}

// co.parry.hoa.lux.financial.SourceInvestmentTransaction
export class SourceInvestmentTransaction {
  accountInfo: string;
  date: any /*java.time.LocalDate*/;
  assetType: AssetType;
  assetId: string;
  quantity: number;
  price: number;
  fees: number;
  amount: number;
  description: string;
  memo: string;
  existingInvestmentTransaction: AssetTransaction;

  constructor(accountInfo: string, date: any /*java.time.LocalDate*/, assetType: AssetType, assetId: string, quantity: number, price: number, fees: number, amount: number, description: string, memo: string, existingInvestmentTransaction: AssetTransaction) {
    this.accountInfo = accountInfo;
    this.date = date;
    this.assetType = assetType;
    this.assetId = assetId;
    this.quantity = quantity;
    this.price = price;
    this.fees = fees;
    this.amount = amount;
    this.description = description;
    this.memo = memo;
    this.existingInvestmentTransaction = existingInvestmentTransaction;
  }
}

// co.parry.hoa.lux.financial.TransactionImport
export class TransactionImport {
  id: string;
  version: number;
  financialModuleId: string;
  source: string;
  fileType: string;
  fileSize: number;
  fileId: string /*FileId*/;
  transactionImportGroups: TransactionImportGroup[];
  errorMessage: string;
  processedTimestamp: string /*Instant*/;
  overrideAccountId: string;
  createdByUserId: string;
  createdTimestamp: string /*Instant*/;
  deletedByUserId: string;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, source: string, fileType: string, fileSize: number, fileId: string /*FileId*/, transactionImportGroups: TransactionImportGroup[], errorMessage: string, processedTimestamp: string /*Instant*/, overrideAccountId: string, createdByUserId: string, createdTimestamp: string /*Instant*/, deletedByUserId: string, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.source = source;
    this.fileType = fileType;
    this.fileSize = fileSize;
    this.fileId = fileId;
    this.transactionImportGroups = transactionImportGroups;
    this.errorMessage = errorMessage;
    this.processedTimestamp = processedTimestamp;
    this.overrideAccountId = overrideAccountId;
    this.createdByUserId = createdByUserId;
    this.createdTimestamp = createdTimestamp;
    this.deletedByUserId = deletedByUserId;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.TransactionImportGroup
export class TransactionImportGroup {
  accountId: string;
  unmodifiedTransactions: number;
  creditDebitTransactionsToCreate: SourceCreditDebitTransaction[];
  creditDebitTransactionsToUpdate: SourceCreditDebitTransaction[];
  creditDebitTransactionsToDelete: CreditDebitTransaction[];
  creditDebitTransactionsToIgnore: SourceCreditDebitTransaction[];
  investmentTransactionsToCreate: SourceInvestmentTransaction[];
  investmentTransactionsToUpdate: SourceInvestmentTransaction[];
  investmentTransactionsToDelete: AssetTransaction[];
  investmentTransactionsToIgnore: SourceInvestmentTransaction[];

  constructor(accountId: string, unmodifiedTransactions: number, creditDebitTransactionsToCreate: SourceCreditDebitTransaction[], creditDebitTransactionsToUpdate: SourceCreditDebitTransaction[], creditDebitTransactionsToDelete: CreditDebitTransaction[], creditDebitTransactionsToIgnore: SourceCreditDebitTransaction[], investmentTransactionsToCreate: SourceInvestmentTransaction[], investmentTransactionsToUpdate: SourceInvestmentTransaction[], investmentTransactionsToDelete: AssetTransaction[], investmentTransactionsToIgnore: SourceInvestmentTransaction[]) {
    this.accountId = accountId;
    this.unmodifiedTransactions = unmodifiedTransactions;
    this.creditDebitTransactionsToCreate = creditDebitTransactionsToCreate;
    this.creditDebitTransactionsToUpdate = creditDebitTransactionsToUpdate;
    this.creditDebitTransactionsToDelete = creditDebitTransactionsToDelete;
    this.creditDebitTransactionsToIgnore = creditDebitTransactionsToIgnore;
    this.investmentTransactionsToCreate = investmentTransactionsToCreate;
    this.investmentTransactionsToUpdate = investmentTransactionsToUpdate;
    this.investmentTransactionsToDelete = investmentTransactionsToDelete;
    this.investmentTransactionsToIgnore = investmentTransactionsToIgnore;
  }
}

// co.parry.hoa.lux.financial.UIAccount
export class UIAccount {
  id: string;
  version: number;
  financialModuleId: string;
  name: string;
  type: AccountType;
  emailHookId: string;
  accountNumber: string;
  nameForSync: string;
  forEnvelopes: boolean;
  investmentPurposeId: string;
  accountInvestmentType: AccountInvestmentType;
  creditLimit: number;
  lastFullSync: string /*Instant*/;
  defaultIncomeSourceId: string;
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;
  balanceWithoutPending: number;
  balanceWithPending: number;

  constructor(id: string, version: number, financialModuleId: string, name: string, type: AccountType, emailHookId: string, accountNumber: string, nameForSync: string, forEnvelopes: boolean, investmentPurposeId: string, accountInvestmentType: AccountInvestmentType, creditLimit: number, lastFullSync: string /*Instant*/, defaultIncomeSourceId: string, createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/, balanceWithoutPending: number, balanceWithPending: number) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.name = name;
    this.type = type;
    this.emailHookId = emailHookId;
    this.accountNumber = accountNumber;
    this.nameForSync = nameForSync;
    this.forEnvelopes = forEnvelopes;
    this.investmentPurposeId = investmentPurposeId;
    this.accountInvestmentType = accountInvestmentType;
    this.creditLimit = creditLimit;
    this.lastFullSync = lastFullSync;
    this.defaultIncomeSourceId = defaultIncomeSourceId;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
    this.balanceWithoutPending = balanceWithoutPending;
    this.balanceWithPending = balanceWithPending;
  }
}

// co.parry.hoa.lux.financial.UIEnvelope
export class UIEnvelope {
  id: string;
  version: number;
  financialModuleId: string;
  name: string;
  amount: number;
  totalAmount: number;
  transactionCount: number;
  earmarks: Earmark[];
  hideWhenZero: boolean;
  position: number;
  userEditable: boolean;
  targetInterval: TargetInterval;
  targetAmount: number;
  maxAmountPerPaycheck: number;
  description: string;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, name: string, amount: number, totalAmount: number, transactionCount: number, earmarks: Earmark[], hideWhenZero: boolean, position: number, userEditable: boolean, targetInterval: TargetInterval, targetAmount: number, maxAmountPerPaycheck: number, description: string, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.name = name;
    this.amount = amount;
    this.totalAmount = totalAmount;
    this.transactionCount = transactionCount;
    this.earmarks = earmarks;
    this.hideWhenZero = hideWhenZero;
    this.position = position;
    this.userEditable = userEditable;
    this.targetInterval = targetInterval;
    this.targetAmount = targetAmount;
    this.maxAmountPerPaycheck = maxAmountPerPaycheck;
    this.description = description;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.UITransaction
export class UITransaction {
  id: string;
  version: number;
  financialModuleId: string;
  accountId: string;
  date: any /*java.time.LocalDate*/;
  amount: number;
  description: string;
  pending: boolean;
  memo: string;
  sourceId: string;
  incomeSourceId: string;
  envelopes: EnvelopeTransaction[];
  newEnvelopes: EnvelopeTransaction[];
  assetType: AssetType;
  assetId: string;
  quantity: number;
  price: number;
  fees: number;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, accountId: string, date: any /*java.time.LocalDate*/, amount: number, description: string, pending: boolean, memo: string, sourceId: string, incomeSourceId: string, envelopes: EnvelopeTransaction[], newEnvelopes: EnvelopeTransaction[], assetType: AssetType, assetId: string, quantity: number, price: number, fees: number, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.accountId = accountId;
    this.date = date;
    this.amount = amount;
    this.description = description;
    this.pending = pending;
    this.memo = memo;
    this.sourceId = sourceId;
    this.incomeSourceId = incomeSourceId;
    this.envelopes = envelopes;
    this.newEnvelopes = newEnvelopes;
    this.assetType = assetType;
    this.assetId = assetId;
    this.quantity = quantity;
    this.price = price;
    this.fees = fees;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.investment.AssetAllocation
export class AssetAllocation {
  assetClassId: string;
  weight: number;

  constructor(assetClassId: string, weight: number) {
    this.assetClassId = assetClassId;
    this.weight = weight;
  }
}

// co.parry.hoa.lux.financial.investment.AssetClass
export class AssetClass {
  id: string;
  version: number;
  financialModuleId: string;
  name: string;
  priority: number;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, name: string, priority: number, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.name = name;
    this.priority = priority;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.investment.AssetTransaction
export class AssetTransaction {
  id: string;
  version: number;
  financialModuleId: string;
  accountId: string;
  date: any /*java.time.LocalDate*/;
  assetType: AssetType;
  assetId: string;
  quantity: number;
  price: number;
  fees: number;
  amount: number;
  description: string;
  memo: string;
  sourceId: string;
  incomeSourceId: string;
  createdTimestamp: string /*Instant*/;
  updatedTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, accountId: string, date: any /*java.time.LocalDate*/, assetType: AssetType, assetId: string, quantity: number, price: number, fees: number, amount: number, description: string, memo: string, sourceId: string, incomeSourceId: string, createdTimestamp: string /*Instant*/, updatedTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.accountId = accountId;
    this.date = date;
    this.assetType = assetType;
    this.assetId = assetId;
    this.quantity = quantity;
    this.price = price;
    this.fees = fees;
    this.amount = amount;
    this.description = description;
    this.memo = memo;
    this.sourceId = sourceId;
    this.incomeSourceId = incomeSourceId;
    this.createdTimestamp = createdTimestamp;
    this.updatedTimestamp = updatedTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.investment.ChartData
export class ChartData {
  labels: string[];
  datasets: ChartDataset[];

  constructor(labels: string[], datasets: ChartDataset[]) {
    this.labels = labels;
    this.datasets = datasets;
  }
}

// co.parry.hoa.lux.financial.investment.ChartDataset
export class ChartDataset {
  label: string;
  data: number[];
  stack: string;
  hidden: boolean;
  average: number;

  constructor(label: string, data: number[], stack: string, hidden: boolean, average: number) {
    this.label = label;
    this.data = data;
    this.stack = stack;
    this.hidden = hidden;
    this.average = average;
  }
}

// co.parry.hoa.lux.financial.investment.FinancialModuleSecurity
export class FinancialModuleSecurity {
  id: string;
  version: number;
  financialModuleId: string;
  name: string;
  syncKey: string;
  expenseRatio: number;
  assetAllocations: AssetAllocation[];
  securityId: string;
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, name: string, syncKey: string, expenseRatio: number, assetAllocations: AssetAllocation[], securityId: string, createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.name = name;
    this.syncKey = syncKey;
    this.expenseRatio = expenseRatio;
    this.assetAllocations = assetAllocations;
    this.securityId = securityId;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.investment.InvestmentPurpose
export class InvestmentPurpose {
  id: string;
  version: number;
  financialModuleId: string;
  name: string;
  position: number;
  allocations: AssetAllocation[];
  createdTimestamp: string /*Instant*/;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, financialModuleId: string, name: string, position: number, allocations: AssetAllocation[], createdTimestamp: string /*Instant*/, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.financialModuleId = financialModuleId;
    this.name = name;
    this.position = position;
    this.allocations = allocations;
    this.createdTimestamp = createdTimestamp;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.financial.investment.Security
export class Security {
  id: string;
  version: number;
  symbol: string;
  name: string;
  expenseRatio: number;
  assetAllocations: AssetAllocation[];
  checkedTimestamp: string /*Instant*/;
  activeForSync: boolean;

  constructor(id: string, version: number, symbol: string, name: string, expenseRatio: number, assetAllocations: AssetAllocation[], checkedTimestamp: string /*Instant*/, activeForSync: boolean) {
    this.id = id;
    this.version = version;
    this.symbol = symbol;
    this.name = name;
    this.expenseRatio = expenseRatio;
    this.assetAllocations = assetAllocations;
    this.checkedTimestamp = checkedTimestamp;
    this.activeForSync = activeForSync;
  }
}

// co.parry.hoa.lux.financial.investment.UIHolding
export class UIHolding {
  accountId: string;
  name: string;
  allocations: AssetAllocation[];
  quantity: number;
  priceDate: any /*java.time.LocalDate*/;
  price: number;
  amount: number;

  constructor(accountId: string, name: string, allocations: AssetAllocation[], quantity: number, priceDate: any /*java.time.LocalDate*/, price: number, amount: number) {
    this.accountId = accountId;
    this.name = name;
    this.allocations = allocations;
    this.quantity = quantity;
    this.priceDate = priceDate;
    this.price = price;
    this.amount = amount;
  }
}

// co.parry.hoa.lux.household.Household
export class Household {
  id: string;
  version: number;
  name: string;
  enabled: boolean;

  constructor(id: string, version: number, name: string, enabled: boolean) {
    this.id = id;
    this.version = version;
    this.name = name;
    this.enabled = enabled;
  }
}

// co.parry.hoa.lux.list.List
export class List {
  id: string;
  version: number;
  listHolderId: string;
  name: string;
  createdByUserId: string;
  createdTimestamp: string /*Instant*/;
  deletedByUserId: string;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, listHolderId: string, name: string, createdByUserId: string, createdTimestamp: string /*Instant*/, deletedByUserId: string, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.listHolderId = listHolderId;
    this.name = name;
    this.createdByUserId = createdByUserId;
    this.createdTimestamp = createdTimestamp;
    this.deletedByUserId = deletedByUserId;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.list.ListHolder
export class ListHolder {
  id: string;
  version: number;
  householdId: string;
  name: string;
  createdByUserId: string;
  createdTimestamp: string /*Instant*/;
  deletedByUserId: string;
  deletedTimestamp: string /*Instant*/;

  constructor(id: string, version: number, householdId: string, name: string, createdByUserId: string, createdTimestamp: string /*Instant*/, deletedByUserId: string, deletedTimestamp: string /*Instant*/) {
    this.id = id;
    this.version = version;
    this.householdId = householdId;
    this.name = name;
    this.createdByUserId = createdByUserId;
    this.createdTimestamp = createdTimestamp;
    this.deletedByUserId = deletedByUserId;
    this.deletedTimestamp = deletedTimestamp;
  }
}

// co.parry.hoa.lux.list.ListItem
export class ListItem {
  id: string;
  version: number;
  listId: string;
  createTimestamp: string /*Instant*/;
  text: string;
  position: number;
  closedTimestamp: string /*Instant*/;
  closedByUserId: string;
  deletedTimestamp: string /*Instant*/;
  deletedByUserId: string;

  constructor(id: string, version: number, listId: string, createTimestamp: string /*Instant*/, text: string, position: number, closedTimestamp: string /*Instant*/, closedByUserId: string, deletedTimestamp: string /*Instant*/, deletedByUserId: string) {
    this.id = id;
    this.version = version;
    this.listId = listId;
    this.createTimestamp = createTimestamp;
    this.text = text;
    this.position = position;
    this.closedTimestamp = closedTimestamp;
    this.closedByUserId = closedByUserId;
    this.deletedTimestamp = deletedTimestamp;
    this.deletedByUserId = deletedByUserId;
  }
}

// co.parry.hoa.lux.list.UIList
export class UIList {
  id: string;
  list: List;
  listItems: ListItem[];

  constructor(id: string, list: List, listItems: ListItem[]) {
    this.id = id;
    this.list = list;
    this.listItems = listItems;
  }
}

// co.parry.hoa.lux.list.UIListHolder
export class UIListHolder {
  id: string;
  listHolder: ListHolder;
  lists: List[];

  constructor(id: string, listHolder: ListHolder, lists: List[]) {
    this.id = id;
    this.listHolder = listHolder;
    this.lists = lists;
  }
}

// co.parry.hoa.lux.module.UIModule
export class UIModule {
  id: string;
  name: string;
  className: string;
  icon: string;
  url: string;
  color: string;
  subModules: UIModule[];
  actionItemsId: string;

  constructor(id: string, name: string, className: string, icon: string, url: string, color: string, subModules: UIModule[], actionItemsId: string) {
    this.id = id;
    this.name = name;
    this.className = className;
    this.icon = icon;
    this.url = url;
    this.color = color;
    this.subModules = subModules;
    this.actionItemsId = actionItemsId;
  }
}

/* eslint-enable */
// END GENERATED FUNCTIONS
