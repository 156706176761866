import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{class:'mx-auto my-' +  (_vm.inDialog ? '0' : '4'),style:('' + (_vm.inDialog ? 'background:#121212;' : '')),attrs:{"outlined":"","max-width":_vm.wide ? 3000 : 1023,"to":_vm.to,"color":_vm.errorTheme ? '#FF0000' : undefined}},[_c(VCardTitle,[(_vm.icon)?_c(VIcon,{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_c('span',{style:('flex: 1; word-break: break-word;')},[_vm._v(_vm._s(_vm.title))]),(_vm.titleRight)?_c('span',{style:('padding-left: 16px;' + (_vm.titleRightColor ? 'color: ' + _vm.titleRightColor + ';' : ''))},[_vm._v(_vm._s(_vm.titleRight))]):_vm._e(),(_vm.actions && _vm.actions.length == 1)?_c(VBtn,{attrs:{"color":_vm.actions[0].color,"fab":!!_vm.actions[0].color,"small":!!_vm.actions[0].color,"icon":!_vm.actions[0].color},on:{"click":_vm.actions[0].click}},[_c(VIcon,[_vm._v(_vm._s(_vm.actions[0].icon))])],1):(_vm.actions && _vm.actions.length > 1)?_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{staticStyle:{"background":"#121212"}},_vm._l((_vm.actions),function(item,index){return _c(VListItem,{key:index,on:{"click":item.click}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)}),1)],1):(_vm.launchTo)?_c(VBtn,{attrs:{"icon":"","to":_vm.launchTo}},[_c(VIcon,[_vm._v("mdi-launch")])],1):(_vm.collapsible)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.expanded = !_vm.expanded; _vm.$emit('expanded', _vm.expanded)}}},[(_vm.expanded)?_c(VIcon,[_vm._v("mdi-chevron-up")]):_c(VIcon,[_vm._v("mdi-chevron-down")])],1):_vm._e()],1),(_vm.expanded || !_vm.collapsible)?_c(VCardText,[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }