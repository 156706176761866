<template>
  <div>
<!--    <Card icon="mdi-cash-multiple" title="Transactions" wide :actions="[{icon: 'mdi-email-search', click: this.searchForTransactions, color: 'primary'}]"-->
    <Card icon="mdi-cash-multiple" title="Transactions" wide :actions="[{icon: 'mdi-cash-plus', click: () => {showCreateTransaction = true;}, color: 'primary'}]"
    >
      <v-container fluid style="padding: 0px">
        <v-row dense>
          <v-col :cols="searchBoxSizes[0]">
            <v-select
              v-model="selectedAccounts"
              :items="accounts"
              item-text="name"
              item-value="id"
              item-disabled="ignore"
              label="Accounts"
              prepend-inner-icon="mdi-bank"
              dense
              outlined
              multiple
            >
              <template v-slot:selection="{ index, item }">
                <span v-if="index === 0">
                  <span v-if="selectedAccounts.length === 1">{{item.name}}</span>
                  <span v-else-if="selectedAccounts.length === accounts.length">All Accounts</span>
                  <span v-else>{{selectedAccounts.length}} Accounts</span>
                </span>
              </template>

            </v-select>
          </v-col>

          <v-col :cols="searchBoxSizes[1]">
            <v-select
              v-model="selectedEnvelopes"
              :items="envelopes"
              item-text="name"
              item-value="id"
              label="Envelopes"
              prepend-inner-icon="mdi-email"
              dense
              outlined
              multiple
            >
              <template v-slot:selection="{ index, item }">
                <span v-if="index === 0">
                  <span v-if="selectedEnvelopes.length === 1">{{item.name}}</span>
                  <span v-else-if="selectedEnvelopes.length === envelopes.length">All Envelopes</span>
                  <span v-else>{{selectedEnvelopes.length}} Envelopes</span>
                </span>
              </template>
            </v-select>
          </v-col>

          <v-col :cols="searchBoxSizes[2]">
            <v-select
              v-model="selectedIncomeSources"
              :items="incomeSourcesForDropdown"
              item-text="name"
              item-value="id"
              label="Income Sources"
              prepend-inner-icon="mdi-cash-marker"
              dense
              outlined
              multiple
            >
              <template v-slot:selection="{ index, item }">
                <span v-if="index === 0">
                  <span v-if="selectedIncomeSources.length === 1">{{item.name}}</span>
                  <span v-else-if="selectedIncomeSources.length === envelopes.length">All Sources</span>
                  <span v-else>{{selectedIncomeSources.length}} Sources</span>
                </span>
              </template>
            </v-select>
          </v-col>

          <v-col :cols="searchBoxSizes[3]">
            <v-select
              v-model="selectedCreditDebits"
              :items="['Credits and Debits', 'Credits', 'Debits']"
              dense
              outlined
            >
            </v-select>
          </v-col>

          <!--          <v-spacer/>-->
          <v-col :cols="searchBoxSizes[4]">
            <v-menu
              ref="showStartDatePickerMenu"
              v-model="showStartDatePickerMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="searchStartDate"
                  prepend-inner-icon="mdi-calendar"
                  dense
                  outlined
                  label="Start Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="searchStartDate"
                color="primary"
                no-title
                scrollable
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col :cols="searchBoxSizes[5]">
            <v-menu
              ref="showEndDatePickerMenu"
              v-model="showEndDatePickerMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="searchEndDate"
                  prepend-inner-icon="mdi-calendar"
                  dense
                  outlined
                  label="End Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="searchEndDate"
                color="primary"
                no-title
                scrollable
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col :cols="searchBoxSizes[6]">
            <v-currency-field
              v-model="searchAmount"
              label="Amount"
              prepend-inner-icon="mdi-currency-usd"
              dense
              outlined
              @keyup.native.enter="searchForTransactions"
            />
          </v-col>
          <v-col :cols="searchBoxSizes[7]">
            <v-text-field
              v-model="searchText"
              prepend-inner-icon="mdi-magnify"
              dense
              outlined
              @keyup.native.enter="searchForTransactions"
            />
          </v-col>
          <v-col cols="1">
            <v-btn small fab color="primary" @click="searchForTransactions" :disabled="searching">
              <v-icon>mdi-email-search</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div v-if="transactions && transactions.length > 0 && !searching" style="margin: auto;">
        <span style="padding-right: 16px;"><b>Count: </b>{{transactions.length}}</span>
        <span style="padding-right: 16px;"><b>Debits: </b>{{formatMoney(sumAmounts(transactions, false, false))}}</span>
        <span style="padding-right: 16px;"><b>Credits: </b>{{formatMoney(sumAmounts(transactions, true, false))}}</span>
        <span><b>Total: </b>{{formatMoney(sumAmounts(transactions, false, true))}}</span>
        <br/>
        <span
          v-for="item in groupByIncomeSource"
          v-bind:key="item.name"
          style="padding-right: 16px;"
        >
          <b>{{item.name}}: </b>{{formatMoney(item.amount)}}
        </span>
      </div>
<!--      <v-spacer />-->
<!--      <div style="float: right; margin: auto;">-->
<!--        <v-btn small color="primary" fab :elevation="0" @click="showCreateTransaction = true;">-->
<!--          <v-icon>mdi-cash-plus</v-icon>-->
<!--        </v-btn>-->
<!--      </div>-->
<!--      <br/>-->
      <!--        v-if="transactions && transactions.length > 0"-->

      <TransactionTable
        v-if="transactions && !searching"
        :financialModule="financialModule"
        :accounts="accounts"
        :envelopes="envelopes"
        :mySecurities="mySecurities"
        :incomeSources="incomeSources"
        :transactions="transactions"
        :envelopeIdToAdd="selectedEnvelopes && selectedEnvelopes.length === 1 ? selectedEnvelopes[0] : null"
        />
      <v-card-actions>
        <v-spacer/>

      </v-card-actions>
    </Card>

    <v-dialog v-model="showCreateTransaction" max-width="1023">
      <Card title="Create Transactions" icon="mdi-cash-plus" inDialog>
        <DynamicForm
          :key="showCreateTransaction"
          :object="{id: 'empty', version: 0, date: new Date(new Date() - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().split('T')[0], financialModuleId: financialModule.id, accountId: selectedAccounts && selectedAccounts.length == 1 ? selectedAccounts[0] : null, assetType: 'FINANCIAL_MODULE_SECURITY'}"
          :fields="[
            {fieldName:'date', displayName:'Date', type: 'LocalDate', small: true, rules: [rules.required]},
            {fieldName:'accountId', displayName:'Account', type: 'Enumerator', values: accountsForDropdown, rules: [rules.required]},
            {fieldName:'assetType', displayName:'Asset Type', type: 'Enumerator', values: getEnumArray(assetType), rules: [rules.required], displayCondition: (o) => isAssetAccount(o.accountId), readOnly: true},
            {fieldName:'assetId', displayName:'Security', type: 'Enumerator', values: securitiesForDropdown, rules: [rules.required], displayCondition: (o) => isAssetAccount(o.accountId) && o.assetType === 'FINANCIAL_MODULE_SECURITY'},
            {fieldName:'description', displayName:'Description', type: String},
            {fieldName:'pending', displayName:'Pending', type: Boolean, displayCondition: (o) => !isAssetAccount(o.accountId)},
            {fieldName:'quantity', displayName:'Quantity', type: Number, rules: [rules.required], displayCondition: (o) => isAssetAccount(o.accountId)},
            {fieldName:'fees', displayName:'Fees', type: 'Currency', displayCondition: (o) => isAssetAccount(o.accountId)},
            {fieldName:'amount', displayName:'Total Amount', type: 'Currency', rules: [rules.required]},
            ]"
          :save-method="(data) => this.$api.financial_transaction.createTransaction(data)"
          :cancel-callback="() => this.showCreateTransaction = false"
          :success-callback="(o) => {if (o.envelopes) {o.newEnvelopes = JSON.parse(JSON.stringify(o.envelopes))} this.transactions.splice(0, 0, o); this.showCreateTransaction = false;}"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>
  </div>
</template>

<script>
import Card from '@/components/Card'
import financialModule from '@/hoamodules/financial/FinancialModule'
import DynamicForm from '@/components/DynamicForm.vue'
import { AssetType } from '@/api'
import TransactionTable from '@/hoamodules/financial/TransactionTable.vue'

// import EditableTable from '@/components/EditableTable'

export default {
  name: 'Transactions',
  components: { TransactionTable, DynamicForm, Card },
  props: ['command', 'initialId', 'household', 'financialModule', 'accounts', 'envelopes', 'mySecurities', 'incomeSources'],

  data: () => ({
    transactions: [],
    showStartDatePickerMenu: false,
    showEndDatePickerMenu: false,

    selectedAccounts: [],
    selectedEnvelopes: [],
    selectedIncomeSources: [],
    selectedCreditDebits: "Credits and Debits",
    searchStartDate: new Date(Date.now() - (new Date().getTimezoneOffset() * 60000) - (90 * 86400000)).toISOString().split('T')[0],
    searchEndDate: null,
    searchText: '',
    searchAmount: 0,
    searching: false,

    assetType: AssetType,
    showCreateTransaction: false,

    rules: {
      required: value => !!value || 'Required',
    },
  }),

  computed: {
    // envelopeAccounts () {
    //   return this.accounts.filter(account => {
    //     return account.purpose === "Envelope";
    //   });
    // },
    accountNames() {
      const names = {};
      this.accounts.forEach(account => {
        names[account.id] = account.name;
      });
      return names;
    },
    accountsForDropdown() {
      const parsableArray = [];
      this.accounts.forEach(item => {
        parsableArray.push({
          text: item.name,
          value: item.id
        });
      });
      return parsableArray;
    },
    securitiesForDropdown() {
      const parsableArray = [];
      this.mySecurities.forEach(item => {
        parsableArray.push({
          text: item.name,
          value: item.id
        });
      });
      return parsableArray;
    },
    searchBoxSizes() {
      if (this.$vuetify.breakpoint.xs) {
        return [6, 6, 6, 6, 6, 5, 3, 6];
      } else if (this.$vuetify.breakpoint.sm) {
        return [4, 4, 4, 4, 4, 4, 3, 8];
      } else if (this.$vuetify.breakpoint.md) {
        return [4, 4, 4, 4, 4, 4, 3, 8];
      } else if (this.$vuetify.breakpoint.lg) {
        return [2, 2, 2, 2, 2, 2, 3, 8];
      } else {
        return [2, 2, 2, 2, 2, 2, 3, 8];
      }
    },
    incomeSourcesForDropdown() {
      const items = [{ id: "null", name: "None" }];
      this.incomeSources.forEach(item => {
        items.push(item);
      });
      return items;
    },
    groupByIncomeSource() {
      const items = [];
      this.transactions.forEach(transaction => {
        let found = false;

        let transactionAmount = transaction.amount;
        if (this.selectedEnvelopes && this.selectedEnvelopes.length > 0) {
          transactionAmount = 0;
          if (transaction.envelopes) {
            transaction.envelopes.forEach(transactionEnvelope => {
              this.selectedEnvelopes.forEach(selectedEnvelope => {
                if (selectedEnvelope === transactionEnvelope.envelopeId)
                  transactionAmount += transactionEnvelope.amount;
              });
            });
          }
        }

        items.forEach(item => {
          if (item.id === transaction.incomeSourceId) {
            found = true;
            item.amount += transactionAmount;
          }
        });
        if (!found) {
          let name = "Expense";
          if (transaction.incomeSourceId) {
            this.incomeSources.forEach(incomeSource => {
              if (incomeSource.id === transaction.incomeSourceId)
                name = incomeSource.name;
            });
          }
          items.push({
            id: transaction.incomeSourceId,
            name: name,
            amount: transactionAmount
          });
        }
      });
      return items;
    }
  },

  methods: {
    searchForTransactions() {
      this.transactions = [];
      this.searching = true;
      this.$api.financial_transaction.getTransactions(this.financialModule.id, this.selectedAccounts, this.selectedEnvelopes,
        this.searchStartDate, this.searchEndDate, this.searchText, this.searchAmount, this.selectedIncomeSources, this.selectedCreditDebits).then(transactions => {
        transactions.forEach(newTransaction => {
          if (newTransaction.envelopes)
            newTransaction.newEnvelopes = JSON.parse(JSON.stringify(newTransaction.envelopes));
        });
        transactions.sort((a, b) => b.date.localeCompare(a.date));
        this.transactions = transactions;
        this.searching = false;
      });
    },
    sumAmounts(transactions, positive, total) {
      let amount = 0;
      transactions.forEach(transaction => {
        let transactionAmount = transaction.amount;
        if (this.selectedEnvelopes && this.selectedEnvelopes.length > 0) {
          transactionAmount = 0;
          if (transaction.envelopes) {
            transaction.envelopes.forEach(transactionEnvelope => {
              this.selectedEnvelopes.forEach(selectedEnvelope => {
                if (selectedEnvelope === transactionEnvelope.envelopeId)
                  transactionAmount += transactionEnvelope.amount;
              });
            });
          }
        }
        if (total) {
          amount += transactionAmount;
        } else if (transactionAmount > 0 && positive) {
          amount += transactionAmount;
        } else if (transactionAmount < 0 && !positive) {
          amount += transactionAmount;
        }
      });
      return amount;
    },
    isAssetAccount(id) {
      let isAssetAccount = false;
      this.accounts.forEach(item => {
        if (id === item.id && item.type === 'INVESTMENT')
          isAssetAccount = true;
      });
      return isAssetAccount;
    },

  },

  mounted() {
    this.$emit('breadcrumbs', [
      {
        text: 'Transactions',
        icon: 'mdi-cash-multiple',
        to: '/h/' + this.household.id + '/f/' + this.financialModule.id + '/transactions'
      }
    ]);
    let runSearch = true;

    if (this.command === 's') {
      this.searchText = 'source:' + this.initialId;
      runSearch = true;
    }

    if (this.command === 't') {
      this.searchText = 'transaction:' + this.initialId;
      runSearch = true;
    }

    if (this.command === 'a') {
      this.selectedAccounts.push(this.initialId);
      runSearch = true;
    }

    if (this.command === 'e') {
      this.selectedEnvelopes.push(this.initialId);
      runSearch = true;
    }

    if (runSearch)
      this.searchForTransactions();
  },

  watch: {
    financialModule() {
      this.subscribe();
    },
  },
}
</script>

<style scoped>

</style>
