import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../hoamodules/Dashboard.vue'
import Admin from '../hoamodules/admin/Admin.vue'
import AdminDashboard from '../hoamodules/admin/AdminDashboard.vue'
import AdminHouseholds from '../hoamodules/admin/Households.vue'
import AdminUsers from '../hoamodules/admin/AdminUsers.vue'
import AdminNewtons from '../hoamodules/admin/AdminNewtons.vue'
import Repository from '../hoamodules/admin/Repository.vue'
import NotFound from '../components/NotFound.vue'
import Register from '../components/Register.vue'
import UserSettings from '../hoamodules/user/Settings.vue'
import HouseholdSettings from '../hoamodules/household/Settings.vue'
import HouseholdAddApp from "../hoamodules/household/HouseholdAddApp.vue";
import Household from '../hoamodules/household/Household.vue'
import HouseholdDashboard from "../hoamodules/household/HouseholdDashboard.vue";
import ListHolder from "../hoamodules/list/ListHolder.vue";
import HouseholdNewton from "../hoamodules/household/HouseholdNewton.vue";
import FinancialFinancialModule from "../hoamodules/financial/FinancialModule.vue";
import FinancialDashboard from "../hoamodules/financial/Dashboard.vue";
import FinancialEnvelopes from "../hoamodules/financial/Envelopes.vue";
import FinancialEnvelopeParent from "../hoamodules/financial/EnvelopeParent.vue";
import FinancialEnvelope from "../hoamodules/financial/Envelope.vue";
import FinancialAccounts from "../hoamodules/financial/Accounts.vue";
import FinancialSettings from "../hoamodules/financial/Settings.vue";
import FinancialTransactions from "../hoamodules/financial/Transactions.vue";
import FinancialNetWorth from "../hoamodules/financial/NetWorth.vue";
import FinancialInvesting from "../hoamodules/financial/Investing.vue";
import FinancialSync from "../hoamodules/financial/Sync.vue";
import AIDashboard from "@/hoamodules/AIDashboard.vue";
import CalendarDashboard from "@/hoamodules/calendar/CalendarDashboard.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Dashboard,
  },

  // user module
  {
    path: '/account',
    component: UserSettings,
  },

  // household module
  {
    path: '/h/:householdId',
    props: true,
    component: Household,
    children: [
      {
        path: '',
        component: HouseholdDashboard,
      },
      {
        path: 'settings',
        component: HouseholdSettings,
      },
      {
        path: 'add',
        component: HouseholdAddApp,
      },
      {
        path: 'newton/:newtonId',
        props: true,
        component: HouseholdNewton,
      },
      {
        path: 'list/:listHolderId/:listId?',
        props: true,
        component: ListHolder,
      },
      {
        path: 'f/:financialModuleId',
        props: true,
        component: FinancialFinancialModule,
        children: [
          {
            path: '',
            component: FinancialDashboard,
          },
          {
            path: 'envelopes',
            component: FinancialEnvelopeParent,
            children: [
              {
                path: '',
                component: FinancialEnvelopes,
              },
              {
                path: ':envelopeId',
                props: true,
                component: FinancialEnvelope,
              },
            ]
          },
          {
            path: 'accounts',
            component: FinancialAccounts,
          },
          {
            path: 'settings',
            component: FinancialSettings,
          },
          {
            path: 'transactions/:command?/:initialId?',
            props: true,
            component: FinancialTransactions,
          },
          {
            path: 'networth',
            component: FinancialNetWorth
          },
          {
            path: 'investing',
            component: FinancialInvesting
          },
          {
            path: 'sync',
            component: FinancialSync
          },
        ]
      },
      {
        path: 'calendar/:calendarHolderId?',
        props: true,
        component: CalendarDashboard,
      },
    ]
  },
  {
    path: '/register',
    component: Register,
    meta: {
      allowPageWhileUnauthenticated: true
    }
  },
  {
    path: '/admin',
    alias: '/system',
    component: Admin,
    children: [
      {
        path: '',
        component: AdminDashboard,
      },
      {
        path: 'households',
        component: AdminHouseholds,
      },
      {
        path: 'users',
        component: AdminUsers,
      },
      {
        path: 'newtons',
        component: AdminNewtons,
      },
      {
        path: 'repo',
        component: Repository,
      },
    ]
  },

  // ai module
  {
    path: '/ai/:aiId',
    props: true,
    component: AIDashboard,
  },

  {
    path: '*',
    component: NotFound,
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
